import {
	SET_BOOKING_REQUEST,
	SET_BOOKING_SUCCESS,
	SET_BOOKING_FAILED,
	SET_BOOKING_ADULTS,
	SET_BOOKING_CHILDREN,
	SET_BOOKING_DATES,
	SET_BOOKING_ROOMS_COUNT,
	SET_BOOKING_ROOM_ID,
	SET_BOOKING_RATE_ID,
	SET_BOOKING_TOTAL_PRICE,
	SET_BOOKING_TOTAL_PRICE_SALE,
	SET_BOOKING_RESET,
	SET_BOOKING_EXTRA,
	SET_BOOKING_PROMOCODE,
	SET_BOOKING_PROMOCODE_ISDISABLED,
	SET_BOOKING_ROOMCHACKED,
} from '../actions/booking'
import { IBookingState, TBookingActions } from "../types/booking";
import { addOneDay } from "../../utils/helpers";

export const initialState: IBookingState = {
  dates: [new Date(), addOneDay()],
  adults: 2,
  children: 0,
  roomsCount: 0,
  roomID: null,
  rateID: null,
  totalPrice: 0,
  totalPriceSale: 0,
  request: false,
  failed: false,
  extra: [],
  data: null,
  promocode: "",
  isDisabled: false,
  roomChacked: 0,
};

export const bookingReducer = (
  state: IBookingState = initialState,
  action: TBookingActions
) => {
  switch (action.type) {
		case SET_BOOKING_REQUEST: {
			return {
				...state,
				request: true,
				failed: false,
			}
		}
		case SET_BOOKING_SUCCESS: {
			return {
				...state,
				data: action.data,
				request: false,
				failed: false,
			}
		}
		case SET_BOOKING_FAILED: {
			return {
				...state,
				request: false,
				failed: true,
			}
		}
		case SET_BOOKING_DATES: {
			return {
				...state,
				dates: action.dates,
			}
		}
		case SET_BOOKING_ADULTS: {
			return {
				...state,
				adults: action.adults,
			}
		}
		case SET_BOOKING_CHILDREN: {
			return {
				...state,
				children: action.children,
			}
		}
		case SET_BOOKING_ROOMS_COUNT: {
			return {
				...state,
				roomsCount: action.count,
			}
		}
		case SET_BOOKING_ROOM_ID: {
			return {
				...state,
				roomID: action.id,
			}
		}
		case SET_BOOKING_RATE_ID: {
			return {
				...state,
				rateID: action.id,
			}
		}
		case SET_BOOKING_TOTAL_PRICE: {
			return {
				...state,
				totalPrice: action.price,
			}
		}
		case SET_BOOKING_TOTAL_PRICE_SALE: {
			return {
				...state,
				totalPriceSale: action.price,
			}
		}
		case SET_BOOKING_EXTRA: {
			return {
				...state,
				extra: action.extra,
			}
		}
		case SET_BOOKING_RESET: {
			return {
				...initialState,
				data: state.data,
			}
		}
		case SET_BOOKING_PROMOCODE: {
			return {
				...state,
				promocode: action.promocode,
			}
		}
		case SET_BOOKING_PROMOCODE_ISDISABLED: {
			return {
				...state,
				isDisabled: action.isDisabled,
			}
		}
		case SET_BOOKING_ROOMCHACKED: {
			return {
				...state,
				roomChacked: action.roomChacked,
			}
		}
		default: {
			return state
		}
	}
};
