import React, { useEffect } from "react";
import { IRoomBed, IRoomBeds, IRoomSpaces } from "../../utils/types";

import styles from "./spaces.module.scss";

import bedIcon from "../../assets/images/bed.png";
import sizeIcon from "../../assets/images/size.png";
import { FEET_IN_METER } from "../../utils/constants";
import { useAppSelector } from "../../hooks/redux";

const capitalize = (str: string) => {
  const arr: string[] = str.split("_");
  for (let i: number = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ");
};

const Bed = ({ props }: { props: IRoomBed[] }) => {
  const { hotel } = useAppSelector((store) => store.hotelBySlug);
  return (
    <>
      {props.map((bed: IRoomBed, index: number) => {
        return (
          <li
            className={styles.item}
            style={{
              color:
                hotel?.secondary_color !== "#ffffff"
                  ? hotel?.secondary_color
                  : "",
            }}
            key={(bed.size + "-" + index).toString()}
          >
            <img
              className={styles.icon}
              style={{
                color:
                  hotel?.secondary_color !== "#ffffff"
                    ? hotel?.secondary_color
                    : "",
              }}
              src={bedIcon}
              alt=""
            />
            {`${bed.count} ${capitalize(bed.type)}`}
          </li>
        );
      })}
    </>
  );
};

const Beds = ({ data }: { data: IRoomBeds[] }) => {
  return (
    <>
      {data.map((item: IRoomBeds, index: number) => {
        return <Bed props={item.beds} key={("config-" + index).toString()} />;
      })}
    </>
  );
};

const Square = ({ square }: { square: number }) => {
  const { hotel } = useAppSelector((store) => store.hotelBySlug);
  const feetSquare = parseInt(String(square * FEET_IN_METER));
  return (
    <li
      className={styles.item}
      style={{
        color:
          hotel?.secondary_color !== "#ffffff" ? hotel?.secondary_color : "",
      }}
    >
      <img
        className={styles.icon}
        style={{
          color:
            hotel?.secondary_color !== "#ffffff" ? hotel?.secondary_color : "",
        }}
        src={sizeIcon}
        alt="square"
      />
      {`${square} m2 / ${feetSquare} ft2`}
    </li>
  );
};

const Spaces = (props: IRoomSpaces) => {
  return (
    <>
      <Beds data={props.bed_configurations} />
      <Square square={props.square} />
    </>
  );
};

export default Spaces;
