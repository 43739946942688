import React from "react";

import s from "./main-menu.module.scss";
import { useAppSelector } from "../../hooks/redux";
import logo from "../../assets/images/logo.png";

const MainMenu = () => {
  const { title } = useAppSelector((store) => store.property);
  const { hotel } = useAppSelector((store) => store.hotelBySlug);

  return (
    <div className={s.mainMenu}>
      <div className={s.logoWrapper}>
        {hotel?.logo ? (
          <img src={hotel?.logo} alt="logo" />
        ) : (
          <img src={logo} alt="logo" />
        )}
      </div>
    </div>
  );
};

export default MainMenu;
