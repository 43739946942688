import React, { useCallback } from "react";

import styles from "./footer.module.scss";
import restart from "../../assets/images/restart.svg";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { resetBooking } from "../../services/actions/booking";

const Footer = () => {
  const dispatch = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();
  let { id } = useParams();
  const { title, country } = useAppSelector((store) => store.property);
  const { hotel } = useAppSelector((store) => store.hotelBySlug);

  const handleResetBooking = useCallback(() => {
    dispatch(resetBooking());
    navigate(`/${id}/booking/guests`);
  }, []);

  return (
    <footer
      className={styles.container}
      style={{
        background:
          hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
      }}
    >
      <div className={`${styles.text} ${styles.brand}`}>
        {/* <span>{title}</span> */}
        <span>
          <a
            className={styles.brandLink}
            href="https://acemsthailand.com"
            target="_blank"
            style={{
              color: hotel?.fifth_color !== "#ffffff" ? hotel?.fifth_color : "",
            }}
          >
            Powered by ACE Booking Engine
          </a>
        </span>
        {country && (
          <span
            style={{
              color: hotel?.fifth_color !== "#ffffff" ? hotel?.fifth_color : "",
            }}
          >
            , {country}
          </span>
        )}
      </div>
      {/*<div className={`${styles.text} ${styles.modify}`}>*/}
      {/*	<span  className={styles.underline} style={{ color: hotel?.fifth_color !== "#ffffff" ? hotel?.fifth_color : "" }}>Modify</span> / <Link to="cancel" className={styles.underline} style={{ color: hotel?.fifth_color !== "#ffffff" ? hotel?.fifth_color : "" }}>Cancel Reservation</Link>*/}
      {/*</div>*/}
      <div className={styles.startOverRow} onClick={handleResetBooking}>
        <img className={styles.restartImg} src={restart} alt="restart" />
        <div
          className={`${styles.text} ${styles.underline}`}
          style={{
            color: hotel?.fifth_color !== "#ffffff" ? hotel?.fifth_color : "",
          }}
        >
          START OVER
        </div>
      </div>
    </footer>
  );
};

export default Footer;
