import {
  GET_ROOMS_REQUEST,
  GET_ROOMS_SUCCESS,
  GET_ROOMS_FAILED,
} from "../actions/rooms";
import {IRoomsState, TRoomsActions} from "../types/rooms";

export const initialState: IRoomsState = {
  rooms: [],
  request: false,
  failed: false,
}

export const roomsReducer = (state: IRoomsState = initialState, action: TRoomsActions) => {
  switch (action.type) {
    case GET_ROOMS_REQUEST: {
      return {
        ...state,
        request: true,
        failed: false
      };
    }
    case GET_ROOMS_SUCCESS: {
      return {
        ...state,
        request: false,
        rooms: action.rooms,
        failed: false
      };
    }
    case GET_ROOMS_FAILED: {
      return {
        ...state,
        request: false,
        failed: true
      };
    }
    default: {
      return state;
    }
  }
}