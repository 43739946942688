import React, { useEffect, useMemo, useRef, useState } from "react";
import HeaderItem from "../HeaderItem/HeaderItem";
import { AnimatePresence, motion } from "framer-motion";

import styles from "./header.module.scss";
import logo from "../../assets/images/logo.png";
import { ReactComponent as Lang } from "../../assets/images/internet.svg";
import { hexToRgb, useWindowWidth } from "../../utils/helpers";
// import {NavLink} from "react-router-dom";
import { useAppSelector } from "../../hooks/redux";
import { IRoomData } from "../../utils/types";
import s from "../HeaderItem/header-item.module.scss";
import { NavLink } from "react-router-dom";

const Header = () => {
  const width = useWindowWidth();
  const { currency } = useAppSelector((store) => store.property);
  const { rooms } = useAppSelector((store) => store.rooms);
  const { dates, adults, children, totalPrice, roomID } = useAppSelector(
    (store) => store.booking
  );
  const { hotel } = useAppSelector((store) => store.hotelBySlug);
  const [period, setPeriod] = useState<string>("");
  const collapseRef = useRef<HTMLButtonElement>(null);
  const backdropRef = useRef<HTMLDivElement>(null);
  const langRef = useRef<HTMLAnchorElement>(null);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const handleCollapseNav = () => {
    document.body.classList.toggle("no-scroll");
    setCollapsed(!collapsed);
  };

  const handleCloseNav = () => {
    document.body.classList.remove("no-scroll");
    setCollapsed(false);
  };

  const room: IRoomData | undefined = useMemo(
    () => rooms.find((item: IRoomData) => item.id === roomID),
    [rooms, roomID]
  );

  useEffect(() => {
    setIsCollapsed(!(width && width < 1151));
  }, [width]);

  useEffect(() => {
    setCollapsed(isCollapsed);
  }, [isCollapsed]);

  useEffect(() => {
    const collapse = collapseRef.current;
    const backdrop = backdropRef.current;
    const lang = langRef.current;

    collapse?.addEventListener("click", handleCollapseNav);
    backdrop?.addEventListener("click", handleCloseNav);
    lang?.addEventListener("click", handleCloseNav);

    return () => {
      collapse?.removeEventListener("click", handleCollapseNav);
      backdrop?.removeEventListener("click", handleCloseNav);
      lang?.removeEventListener("click", handleCloseNav);
    };
  }, [collapsed, handleCollapseNav]);

  useEffect(() => {
    const day = (dateString: Date) => {
      const date: Date = new Date(dateString);
      return date.getDate();
    };
    const month = (dateString: Date) => {
      const date: Date = new Date(dateString);
      return date.toString().split(" ")[1];
    };

    const dayStart = day(dates[0]);
    const dayEnd = day(dates[1]);
    const monthStart = month(dates[0]).toUpperCase();
    const monthEnd = month(dates[1]).toUpperCase();

    const date =
      monthStart === monthEnd
        ? `${monthStart} ${dayStart}-${dayEnd}`
        : `${monthStart}-${dayStart} ${monthEnd}-${dayEnd}`;

    setPeriod(date);
  }, [dates]);

  const overlayStyles = {
    backgroundColor:
      hotel?.primary_color !== "#ffffff" && hotel?.primary_color
        ? `rgba(${hexToRgb(hotel.primary_color)}, 0.5)`
        : "",
  };

  return (
		<header className={styles.header}>
			<div className={styles.container}>
				<div className={styles.logoImg}>
					<NavLink className={s.fill} to={`/${hotel?.slug}`}>
						{/* /?status=success */}
						{/* <img src={logo} alt="logo" /> */}
						<img src={hotel?.logo} alt='logo' />
					</NavLink>
				</div>
				{width && width < 1151 && (
					<>
						<button
							className={styles.collapsedMenu}
							ref={collapseRef}
							style={{
								background:
									hotel?.primary_color !== '#ffffff'
										? hotel?.primary_color
										: '',
							}}
						>
							<div
								className={styles.collapsedMenuText}
								style={{
									color:
										hotel?.fifth_color !== '#ffffff' ? hotel?.fifth_color : '',
								}}
							>
								Your Reservation Details{' '}
							</div>
							<div
								className={styles.collapsedMenuLink}
								style={{
									color:
										hotel?.fifth_color !== '#ffffff' ? hotel?.fifth_color : '',
									borderBottomColor:
										hotel?.fifth_color !== '#ffffff' ? hotel?.fifth_color : '',
								}}
							>
								View / Modify
							</div>
						</button>
						{width >= 768 && (
							<div className={styles.currency}>
								<div
									className={styles.langLink}
									style={{
										background:
											hotel?.primary_color !== '#ffffff'
												? hotel?.primary_color
												: '',
									}}
								>
									<div
										className={styles.title}
										style={{
											color:
												hotel?.fifth_color !== '#ffffff'
													? hotel?.fifth_color
													: '',
										}}
									>{`ENGLISH (${currency})`}</div>
									<Lang
										className={styles.lang}
										style={{
											color:
												hotel?.fifth_color !== '#ffffff'
													? hotel?.fifth_color
													: '',
										}}
									/>
								</div>
							</div>
						)}
					</>
				)}
				{width && width >= 1151 && (
					<nav className={styles.nav}>
						<ul className={styles.list}>
							<li className={styles.listItem}>
								<HeaderItem
									path='guests'
									title='ADULTS / CHILDREN'
									subtitle={adults + '/' + children}
								/>
							</li>
							<li className={styles.listItem}>
								<HeaderItem
									path='dates'
									title='DATES OF STAY'
									subtitle={period}
								/>
							</li>
							<li className={styles.listItem}>
								<HeaderItem
									path='accommodations'
									title='ACCOMMODATIONS'
									subtitle={room ? room.attributes.title : 'Promotional rate'}
								/>
							</li>
							<li className={styles.listItem}>
								<HeaderItem
									path='total'
									title='TOTAL'
									subtitle={`${currency} ${totalPrice ? totalPrice : 0}`}
									allRoomsSubtitle={`ALL ROOMS ${currency} 0`}
								/>
							</li>
							<li className={styles.listItem}>
								<div
									className={s.link}
									style={{
										background:
											hotel?.primary_color !== '#ffffff'
												? hotel?.primary_color
												: '',
									}}
								>
									<div
										className={s.title}
										style={{
											color:
												hotel?.fifth_color !== '#ffffff'
													? hotel?.fifth_color
													: '',
										}}
									>{`ENGLISH (${currency})`}</div>
									<Lang
										className={styles.lang}
										style={{
											color:
												hotel?.fifth_color !== '#ffffff'
													? hotel?.fifth_color
													: '',
										}}
									/>
								</div>
							</li>
						</ul>
					</nav>
				)}
			</div>
			<AnimatePresence>
				{width && width < 1151 && collapsed && (
					<>
						<motion.nav
							initial={{ opacity: 0, y: '-100%' }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: '-100%' }}
							transition={{
								ease: 'linear',
							}}
							className={styles.nav}
						>
							<ul className={styles.list}>
								<li className={styles.listItem}>
									<HeaderItem
										onClick={handleCloseNav}
										path='guests'
										title='ADULTS / CHILDREN'
										subtitle={adults + '/' + children}
									/>
								</li>
								<li className={styles.listItem}>
									<HeaderItem
										onClick={handleCloseNav}
										path='dates'
										title='DATES OF STAY'
										subtitle={period}
									/>
								</li>
								<li className={styles.listItem}>
									<HeaderItem
										onClick={handleCloseNav}
										path='accommodations'
										title='ACCOMMODATIONS'
										subtitle={room ? room.attributes.title : 'Promotional rate'}
									/>
								</li>
								<li className={styles.listItem}>
									<HeaderItem
										onClick={handleCloseNav}
										path='total'
										title='TOTAL'
										subtitle={`${currency} ${totalPrice ? totalPrice : 0}`}
										allRoomsSubtitle={`ALL ROOMS ${currency} 0`}
									/>
								</li>
								<li className={styles.listItem}>
									<div
										className={s.link}
										style={{
											background:
												hotel?.primary_color !== '#ffffff'
													? hotel?.primary_color
													: '',
										}}
									>
										<div
											className={s.title}
											style={{
												color:
													hotel?.fifth_color !== '#ffffff'
														? hotel?.fifth_color
														: '',
											}}
										>
											LANGUAGE & CURRENCY
										</div>
										<div
											className={s.subtitle}
											style={{
												color:
													hotel?.fifth_color !== '#ffffff'
														? hotel?.fifth_color
														: '',
											}}
										>{`English (${currency})`}</div>
									</div>
								</li>
								<div
									className={styles.close}
									style={{
										background:
											hotel?.primary_color !== '#ffffff'
												? hotel?.primary_color
												: '',
									}}
									onClick={handleCloseNav}
								>
									<span className={styles.closeIcon}></span>
								</div>
							</ul>
						</motion.nav>
						<motion.div
							ref={backdropRef}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{
								ease: 'linear',
							}}
							className={styles.backdrop}
							// style={{
							//   backgroundColor: `rgba(${hotel?.primary_color}, 0.5)`,
							// }}
							style={overlayStyles}
						></motion.div>
					</>
				)}
			</AnimatePresence>
		</header>
	)
};

export default Header;
