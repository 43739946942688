import { useMemo } from "react";
import { IHotelPolicy } from "../../utils/types";

import styles from "./reservation.module.scss";
import { useAppSelector } from "../../hooks/redux";

const ReservationTerms = ({ terms }: { terms: IHotelPolicy }) => {
  const { hotel } = useAppSelector((store) => store.hotelBySlug);
  const internetType = useMemo(() => {
    switch (terms.internet_access_type) {
      case "wifi":
        return "WiFi";
      case "wired":
        return "Wired";
      default:
        return "None";
    }
  }, [terms.internet_access_type]);

  const parkingType = useMemo(() => {
    switch (terms.parking_type) {
      case "nearby":
        return "Location Nearby";
      case "on_site":
        return "On Site";
      default:
        return "None";
    }
  }, [terms.parking_type]);

  const smokingPolicy = useMemo(() => {
    switch (terms.smoking_policy) {
      case "permitted_areas_only":
        return "Permitted Areas Only";
      case "no_smoking":
        return "Strictly No Smoking";
      default:
        return "Allowed";
    }
  }, [terms.smoking_policy]);

  const petsPolicy = useMemo(() => {
    switch (terms.pets_policy) {
      case "by_arrangements":
        return "Pets By Arrangements";
      case "assistive_only":
        return "Assistive Animals Only";
      case "allowed":
        return "Pets Allowed";
      default:
        return "Pets Not Allowed";
    }
  }, [terms.pets_policy]);

  return (
    <div className={styles.terms}>
      {terms.title && <h2 className={styles.termsHeading}>{terms.title}</h2>}
      {terms.checkin_from_time && terms.checkin_to_time && (
        <div className={styles.termsItem}>
          <p
            className={styles.termsTitle}
            style={{
              color:
                hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            }}
          >
            CHECK-IN TIME
          </p>
          <p
            className={styles.termsValue}
            style={{
              color:
                hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            }}
          >
            {terms.checkin_from_time} - {terms.checkin_to_time}
          </p>
        </div>
      )}
      {terms.checkout_from_time && terms.checkout_to_time && (
        <div className={styles.termsItem}>
          <p
            className={styles.termsTitle}
            style={{
              color:
                hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            }}
          >
            CHECK-OUT TIME
          </p>
          <p
            className={styles.termsValue}
            style={{
              color:
                hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            }}
          >
            {terms.checkout_from_time} - {terms.checkout_to_time}
          </p>
        </div>
      )}
      {internetType && (
        <div className={styles.termsItem}>
          <p
            className={styles.termsTitle}
            style={{
              color:
                hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            }}
          >
            INTERNET ACCESS TYPE
          </p>
          <p
            className={styles.termsValue}
            style={{
              color:
                hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            }}
          >
            {internetType}
          </p>
        </div>
      )}
      {parkingType && (
        <div className={styles.termsItem}>
          <p
            className={styles.termsTitle}
            style={{
              color:
                hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            }}
          >
            PARKING TYPE
          </p>
          <p
            className={styles.termsValue}
            style={{
              color:
                hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            }}
          >
            {parkingType}
          </p>
        </div>
      )}
      {smokingPolicy && (
        <div className={styles.termsItem}>
          <p
            className={styles.termsTitle}
            style={{
              color:
                hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            }}
          >
            SMOKING POLICY
          </p>
          <p
            className={styles.termsValue}
            style={{
              color:
                hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            }}
          >
            {smokingPolicy}
          </p>
        </div>
      )}
      {petsPolicy && (
        <div className={styles.termsItem}>
          <p
            className={styles.termsTitle}
            style={{
              color:
                hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            }}
          >
            PETS POLICY
          </p>
          <p
            className={styles.termsValue}
            style={{
              color:
                hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            }}
          >
            {petsPolicy}
          </p>
        </div>
      )}
    </div>
  );
};

export default ReservationTerms;
