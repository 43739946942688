import { TRequestGetOptions } from "./types";
import { API_KEY } from "./constants";

const checkApiResponse = (res: Response) => {
  return res.ok ? res.json() : res.json().then((err) => Promise.reject(err));
};

export const apiRequest = (url: string, options?: object) =>
  fetch(url, options).then(checkApiResponse);

export const getOptions: TRequestGetOptions = {
  method: "GET",
  headers: {
    "api-key": API_KEY,
  },
};
