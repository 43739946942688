import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./select.module.scss";
import { ISelectOption, ISelectProps, ISelectState } from "../../utils/types";
import { useAppSelector } from "../../hooks/redux";

const Select = ({
  defaultText,
  defaultValue,
  optionsList,
  onChange,
  selectClass,
  textClass,
  listClass,
  optionClass,
  isRoom,
}: ISelectProps) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLLIElement>(null);
  const [state, setState] = useState<ISelectState>({
    defaultSelectText: defaultText,
    value: defaultValue,
    showOptionList: false,
  });
  const { hotel } = useAppSelector((store) => store.hotelBySlug);

  const { showOptionList, defaultSelectText } = state;

  const handleListDisplay = () => {
    setState({
      ...state,
      showOptionList: !state.showOptionList,
    });
  };

  const handleOptionClick: MouseEventHandler<HTMLLIElement> = (e) => {
    setState({
      ...state,
      defaultSelectText: (e.target as HTMLLIElement).getAttribute("data-name"),
      value: (e.target as HTMLLIElement).getAttribute("data-value"),
      showOptionList: !state.showOptionList,
    });
    onChange &&
      onChange((e.target as HTMLLIElement).getAttribute("data-value"));
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(e.target as Node) &&
        itemRef.current &&
        !itemRef.current.contains(e.target as Node)
      ) {
        setState({
          ...state,
          showOptionList: false,
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOptionList]);

  return (
    <div
      className={`${styles.select} ${selectClass}`}
      // style={{
      //   background:
      //     hotel?.secondary_color !== "#ffffff" ? hotel?.secondary_color : "",
      // }}
    >
      <div
        ref={selectRef}
        onClick={handleListDisplay}
        className={`${
          showOptionList ? styles.text + " " + styles.active : styles.text
        } ${textClass}`}
        style={
          isRoom
            ? {
                color:
                  hotel?.secondary_color !== "#ffffff"
                    ? hotel?.secondary_color
                    : "",
                background:
                  hotel?.fourth_color !== "#ffffff" ? hotel?.fourth_color : "",
              }
            : {
                color:
                  hotel?.third_color !== "#ffffff" ? hotel?.third_color : "",
                background:
                  hotel?.secondary_color !== "#ffffff"
                    ? hotel?.secondary_color
                    : "",
              }
        }
      >
        {defaultSelectText}
      </div>
      <AnimatePresence>
        {showOptionList && (
          <motion.ul
            initial={{ opacity: 0, y: "5%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "5%" }}
            className={`${styles.list} ${listClass}`}
          >
            {optionsList &&
              optionsList.map((option: ISelectOption) => {
                return (
                  <li
                    ref={itemRef}
                    className={`${
                      option.label.toString() === defaultSelectText
                        ? styles.item + " " + styles.selected
                        : styles.item
                    } ${optionClass ? optionClass : ""}`}
                    data-name={option.label}
                    data-value={option.value}
                    key={option.value}
                    onClick={handleOptionClick}
                  >
                    {option.label}
                  </li>
                );
              })}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Select;
