import React from "react";
import { NavLink } from "react-router-dom";
import s from "./header-item.module.scss";
import { ReactComponent as ArrowDown } from "../../assets/images/decrement.svg";
import { IHeaderItem } from "../../utils/types";
import { useWindowWidth } from "../../utils/helpers";
import { useAppSelector } from "../../hooks/redux";

const HeaderItem = ({
  children,
  path,
  title,
  subtitle,
  allRoomsSubtitle,
  onClick,
}: IHeaderItem) => {
  const { totalPrice } = useAppSelector((store) => store.booking);
  const { hotel } = useAppSelector((store) => store.hotelBySlug);
  const width = useWindowWidth();
  const linkClass = ({ isActive }: { isActive: boolean }) =>
    !isActive ? s.link : s.link + " " + s.active;

  const linkStyle = ({ isActive }: { isActive: boolean }) =>
    !isActive
      ? {
          background:
            hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
        }
      : {
          background:
            hotel?.secondary_color !== "#ffffff" ? hotel?.secondary_color : "",
        };

  return (
    <NavLink
      to={path}
      className={linkClass}
      onClick={onClick}
      style={linkStyle}
    >
      <div
        className={s.title}
        style={{
          color: hotel?.fifth_color !== "#ffffff" ? hotel?.fifth_color : "",
        }}
      >
        {title}
      </div>
      {subtitle && (
        <div
          className={s.subtitle}
          style={{
            color: hotel?.fifth_color !== "#ffffff" ? hotel?.fifth_color : "",
          }}
        >
          {subtitle}
        </div>
      )}
      {/* img change currency */}
      {children}
      {allRoomsSubtitle && !totalPrice && (
        <div
          className={s.text}
          style={{
            color: hotel?.fifth_color !== "#ffffff" ? hotel?.fifth_color : "",
          }}
        >
          {allRoomsSubtitle}
        </div>
      )}
      {!allRoomsSubtitle && width && width >= 1151 && (
        <ArrowDown
          className={s.arrowDown}
          style={{
            color: hotel?.fifth_color !== "#ffffff" ? hotel?.fifth_color : "",
          }}
        />
      )}
      {width && width < 1151 && (
        <ArrowDown
          className={s.arrowDown}
          style={{
            color: hotel?.fifth_color !== "#ffffff" ? hotel?.fifth_color : "",
          }}
        />
      )}
    </NavLink>
  );
};

export default HeaderItem;
