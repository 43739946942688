import React, { useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import styles from "./order.module.scss";

import user from "../../assets/images/user.png";
import calendar from "../../assets/images/calendarSvg.svg";
// import { ReactComponent as CalendarSVG } from "../../assets/images/calendarSvg.svg";
import { useAppSelector } from "../../hooks/redux";
import { IRatePlans, IRoomData } from "../../utils/types";
import {
  dateFormatWithDay,
  getDatesInRange,
  numberWithCommas,
} from "../../utils/helpers";

const Order = ({
  room,
  rate,
}: {
  room: IRoomData | undefined;
  rate: IRatePlans | undefined;
}) => {
  const { currency } = useAppSelector((store) => store.property);
  const { dates, adults, roomsCount, promocode } = useAppSelector(
    (store) => store.booking
  );
  const { hotel } = useAppSelector((store) => store.hotelBySlug);
  const [open, setOpen] = useState<boolean>(false);

  const handleCollapse = () => setOpen(!open);

  const days = useMemo(() => {
    return getDatesInRange(new Date(dates[0]), new Date(dates[1]));
  }, [dates]);

  const displayDiscount = (total_price: number) => {
    if (total_price === 0) {
      return "0";
    }
    if (!total_price) return "";

    switch (hotel?.promocodes[0]?.discount_type) {
      case "percent": {
        const precent = Math.ceil(
          total_price - (total_price / 100) * hotel?.promocodes[0]?.discount
        );
        return precent.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      case "sum": {
        const sum = total_price - hotel?.promocodes[0]?.discount;
        return sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  };

  return (
    <div className={styles.result}>
      <motion.div
        className={`${styles.item} ${open ? styles.active : ""}`}
        onClick={handleCollapse}
      >
        <p className={styles.room}>
          {room ? (
            <>
              <span
                className={styles.roomInfo}
                style={{
                  color:
                    hotel?.secondary_color !== "#ffffff"
                      ? hotel?.secondary_color
                      : "",
                }}
              >
                {rate?.title}
              </span>
              <span
                className={styles.roomName}
                style={{
                  color:
                    hotel?.primary_color !== "#ffffff"
                      ? hotel?.primary_color
                      : "",
                }}
              >{`${
                room?.attributes.title && room?.attributes.title + " -"
              } ${roomsCount} ${roomsCount === 1 ? "room" : "rooms"}`}</span>
            </>
          ) : (
            <span
              className={styles.roomInfo}
              style={{
                color:
                  hotel?.primary_color !== "#ffffff"
                    ? hotel?.primary_color
                    : "",
              }}
            >
              No rooms selected
            </span>
          )}
        </p>
        {rate?.total_price && (
          <span className={styles.roomPrice}>
            {promocode
              ? `${currency} ${displayDiscount(+rate?.total_price)}`
              : `${currency} ${numberWithCommas(+rate?.total_price)}`}
          </span>
        )}
      </motion.div>
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ ease: "linear" }}
            className={styles.details}
          >
            <>
              <div className={styles.info}>
                {rate?.length_of_stay && (
                  <div className={styles.infoItem}>
                    {/* <CalendarSVG style={{ color: hotel?.secondary_color !== "#ffffff" ? hotel?.secondary_color : "" }} /> */}
                    <img
                      src={calendar}
                      style={{
                        color:
                          hotel?.secondary_color !== "#ffffff"
                            ? hotel?.secondary_color
                            : "",
                      }}
                      alt=""
                    />
                    <span
                      style={{
                        color:
                          hotel?.primary_color !== "#ffffff"
                            ? hotel?.primary_color
                            : "",
                      }}
                    >
                      {rate?.length_of_stay} Nights
                    </span>
                  </div>
                )}
                {adults > 0 && (
                  <div className={styles.infoItem}>
                    <img
                      src={user}
                      style={{
                        color:
                          hotel?.secondary_color !== "#ffffff"
                            ? hotel?.secondary_color
                            : "",
                      }}
                      alt=""
                    />
                    <span
                      style={{
                        color:
                          hotel?.primary_color !== "#ffffff"
                            ? hotel?.primary_color
                            : "",
                      }}
                    >
                      {adults} Adults
                    </span>
                  </div>
                )}
              </div>
              {days && (
                <ul className={styles.dates}>
                  {days.map((day: Date, index: number) => (
                    <li
                      className={styles.date}
                      key={("day-" + index).toString()}
                    >
                      <span
                        style={{
                          color:
                            hotel?.primary_color !== "#ffffff"
                              ? hotel?.primary_color
                              : "",
                        }}
                      >
                        {dateFormatWithDay(day)}
                      </span>
                      {rate && (
                        <span
                          style={{
                            color:
                              hotel?.primary_color !== "#ffffff"
                                ? hotel?.primary_color
                                : "",
                          }}
                        >{`${currency} ${numberWithCommas(
                          +rate?.total_price / rate?.length_of_stay
                        )}`}</span>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Order;
