import React, { useState, useCallback, useEffect } from "react";
import Calendar from "react-calendar";
import Button from "../../ui/Button/Button";

import "react-calendar/dist/Calendar.css";

import "./dates.scss";
import styles from "./dates.module.scss";

import calendarPrev from "../../assets/images/calendarPrev.svg";
import calendarNext from "../../assets/images/calendarNext.svg";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { setDates } from "../../services/actions/booking";
import { TDates } from "../../utils/types";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { log } from "util";
import { format, isBefore, startOfToday, addDays } from 'date-fns'

const PrevIco = () => {
  return <img src={calendarPrev} alt="prev" />;
};
const NextIco = () => {
  return <img src={calendarNext} alt="next" />;
};

const Dates = () => {
  const dispatch = useAppDispatch();
  let { id } = useParams();
  const navigate: NavigateFunction = useNavigate();
  const { dates } = useAppSelector((store) => store.booking);
  const { closed } = useAppSelector((store) => store.property);
  const { hotel } = useAppSelector((store) => store.hotelBySlug);
  const [value, setValue] = useState<TDates>([dates[0], dates[1]]);

  const changeDates = (dates: TDates) => {
    dispatch(setDates(dates));
    navigate(`/${id}/booking/accommodations`);
  };

  const dateFormat = useCallback((dateString: Date) => {
    const date: Date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }, []);

  useEffect(() => {
    // console.log("CLOSED: ", [...closed][0]);
    console.log('CLOSED: ', [...closed])
  }, [closed]);

  // useEffect(() => {
	// 	if (closed.length > 0) {
	// 		const today = startOfToday()
	// 		let nextAvailableDate = today

	// 		// Находим первую доступную дату после закрытых дат
	// 		while (
	// 			closed.includes(format(nextAvailableDate, 'yyyy-MM-dd')) ||
	// 			isBefore(nextAvailableDate, today)
	// 		) {
	// 			nextAvailableDate = addDays(nextAvailableDate, 1)
	// 		}

	// 		setValue([nextAvailableDate, addDays(nextAvailableDate, 1)])
	// 	}
	// }, [closed])
  useEffect(() => {
		if (closed.length > 0) {
			const today = startOfToday()
			let nextAvailableDate = today

			// Находим первую доступную дату
			while (closed.includes(format(nextAvailableDate, 'yyyy-MM-dd'))) {
				nextAvailableDate = addDays(nextAvailableDate, 1)
			}

			setValue([nextAvailableDate, addDays(nextAvailableDate, 1)])

			// Теперь ищем вторую доступную дату после первой выбранной
			let secondAvailableDate = addDays(nextAvailableDate, 1)
			while (closed.includes(format(secondAvailableDate, 'yyyy-MM-dd'))) {
				secondAvailableDate = addDays(secondAvailableDate, 1)
			}

			setValue([nextAvailableDate, secondAvailableDate])
		}
	}, [closed])

  return (
    <main className={`${styles.calendar} calendar`}>
      <p
        className={styles.title}
        style={{
          color:
            hotel?.secondary_color !== "#ffffff" ? hotel?.secondary_color : "",
        }}
      >{`${dateFormat(dates[0])} - ${dateFormat(dates[1])}`}</p>
      <Calendar
        prevLabel={PrevIco()}
        nextLabel={NextIco()}
        prev2Label={null}
        next2Label={null}
        view="month"
        showDoubleView
        selectRange
        onChange={setValue}
        value={value}
        locale="en-US"
        tileDisabled={({
          activeStartDate,
          date,
          view,
        }: {
          activeStartDate: Date;
          date: Date;
          view: string;
        }) => {
          let day: string = format(date, 'yyyy-MM-dd');
          const today = startOfToday()
          return closed.includes(day) || isBefore(date, today)
        }}
      />
      <div className={styles.actions}>
        {/*<label htmlFor="code" hidden>Code</label>*/}
        {/*<input className="input" type="text" name="code" id="code" placeholder="Add code" />*/}
        <Button
          buttonClass={styles.button}
          onChange={() => changeDates(value)}
          title="UPDATE DATES GUEST"
          containerStyles={{
            background:
              hotel?.secondary_color !== "#ffffff"
                ? hotel?.secondary_color
                : "",
            color: hotel?.third_color !== "#ffffff" ? hotel?.third_color : "",
          }}
        />
      </div>
    </main>
  );
};

export default Dates;
