import { AppDispatch } from "../../utils/types";
import { API_URL, CHANNEL_NAME, PROPERTY_ID } from "../../utils/constants";
import { apiRequest, getOptions } from "../../utils/api";
import { EXTERNAL_ID } from "../../App";

export const GET_ROOMS_REQUEST: "GET_ROOMS_REQUEST" = "GET_ROOMS_REQUEST";
export const GET_ROOMS_SUCCESS: "GET_ROOMS_SUCCESS" = "GET_ROOMS_SUCCESS";
export const GET_ROOMS_FAILED: "GET_ROOMS_FAILED" = "GET_ROOMS_FAILED";

export const getRoomTypes =
  (fromDate: string, toDate: string) => (dispatch: AppDispatch) => {
    dispatch({ type: GET_ROOMS_REQUEST });
    apiRequest(
      // `${API_URL}/${CHANNEL_NAME}/${PROPERTY_ID}/rooms?checkin_date=${fromDate}&checkout_date=${toDate}`,
      `${API_URL}/${CHANNEL_NAME}/${EXTERNAL_ID}/rooms?checkin_date=${fromDate}&checkout_date=${toDate}`,
      getOptions
    )
      .then((response) => {
        console.log("Rooms data: ", response.data);
        if (response) {
          dispatch({ type: GET_ROOMS_SUCCESS, rooms: response.data });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: GET_ROOMS_FAILED });
      });
  };
