import { useEffect, useState } from "react";
import { CountryOption, IBookingData, ISetCookie } from "./types";
import { StylesConfig } from "react-select";
// import SparkPost from 'sparkpost';
// const sparky = new SparkPost('81078c1f3a0900a88cc10c18e526b0f2cc830549')

export const useWindowWidth = () => {
  const [windowSize, setWindowSize] = useState<number | undefined>(undefined);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export const getCookie = (name: string) => {
  const matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : "";
};

export const setCookie = (name: string, value: string, options: ISetCookie) => {
  options = {
    path: "/",
    ...options,
  };
  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);
  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    updatedCookie += "=" + optionValue;
  }
  document.cookie = updatedCookie;
};

export const deleteCookie = (name: string) => {
  setCookie(name, "", { "max-age": -1 });
};

export const addOneDay = (date: Date = new Date()) => {
  date.setDate(date.getDate() + 1);
  return date;
};

export const dateFormat = (dateString: Date) => {
  const date: Date = new Date(dateString);
  let y: string = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
    date
  );
  let m: string = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
    date
  );
  let d: string = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
    date
  );
  return `${y}-${m}-${d}`;
};

export const dateFormatWithDay = (dateString: Date) => {
  const date: Date = new Date(dateString);
  let y: string = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
    date
  );
  let m: string = new Intl.DateTimeFormat("en", { month: "short" }).format(
    date
  );
  let d: string = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
    date
  );
  let w: string = new Intl.DateTimeFormat("en", { weekday: "short" }).format(
    date
  );
  return `${w}, ${m} ${d}, ${y}`;
};

export const numberWithCommas = (x: number) => {
  if (x === 0) {
    return "0";
  }
  if (!x) return "";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getDatesInRange = (startDate: Date, endDate: Date) => {
  const currentDate: Date = new Date(startDate.getTime());
  const dates: Date[] = [];
  while (currentDate < endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  dates.pop();
  return dates;
};

export const colourStyles: StylesConfig<CountryOption> = {
  container: (styles) => ({
    ...styles,
    backgroundColor: "#ECF7EF",
  }),
  control: (styles) => ({
    ...styles,
    borderRadius: 0,
    border: "none",
    padding: "13px 17px",
    height: "50px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      padding: "13px 17px",
      backgroundColor: "#ECF7EF",
      color: "#7DC894",
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: 500,
      "@media only screen and (min-width: 768px)": {
        ...styles,
        fontSize: "20px",
        lineHeight: "24px",
      },
    };
  },
  input: (styles) => ({
    ...styles,
    padding: 0,
    margin: 0,
    height: "24px",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#7DC894",
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 500,
    "@media only screen and (min-width: 768px)": {
      ...styles,
      fontSize: "20px",
      lineHeight: "24px",
    },
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    margin: 0,
    color: "#7DC894",
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 500,
    "@media only screen and (min-width: 768px)": {
      ...styles,
      fontSize: "20px",
      lineHeight: "24px",
    },
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
  indicatorsContainer: (styles, state) => ({ ...styles, color: "#55C5B6" }),
};

export const hexToRgb = (hex: string) => {
  hex = hex.startsWith("#") ? hex.slice(1) : hex;

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `${r}, ${g}, ${b}`;
};

// export const sendHotelNotification = async (bookingData: IBookingData) => {
// 	const hotelTemplateId = 'hotel-booking'

// 	const responce = await sparky.transmissions.send({
// 		options: {
// 			sandbox: false,
// 		},
// 		content: {
// 			template_id: hotelTemplateId,
// 			use_draft_template: false,
// 		},
// 		substitution_data: {
// 			hotel_name: bookingData.booking.meta?.hotel,
// 			reservation_number: bookingData.booking.reservation_id,
// 			recipient_name: `${bookingData.booking.customer.name} ${bookingData.booking.customer.surname}`,
// 			email: bookingData.booking.customer.mail,
// 			total_nights: bookingData.booking.services[0].nights,
// 			total_amount: bookingData.booking.services[0].total_price,
// 			check_in: bookingData.booking.arrival_date,
// 			check_out: bookingData.booking.departure_date,
// 			guests: `${bookingData.booking.services[0].persons} adults`,
// 			cancellation_policy: bookingData.booking.currency,
// 			room_name: bookingData.booking.meta?.rooms?.attributes.title,
// 			room_price: bookingData.booking.services[0].total_price,
// 			extra: bookingData.booking.meta?.extra,
// 			vat: 130.84,
// 		},
// 		recipients: [
// 			{ address: `${bookingData.booking.customer.mail}` },
// 		],
// 	})

// 	return responce
// }

// export const sendClientNotification = async (bookingData: IBookingData) => {
// 	const clientTemplateId = 'test-hotel-booking-confirmation'
  
// 	const responce = await sparky.transmissions.send({
// 		options: {
// 			sandbox: false,
// 		},
// 		content: {
// 			template_id: clientTemplateId,
// 			use_draft_template: false,
// 		},
// 		substitution_data: {
// 			recipient_name: `${bookingData.booking.customer.name} ${bookingData.booking.customer.surname}`,
// 			hotel_name: bookingData.booking.meta?.hotel,
// 			reservation_number: bookingData.booking.reservation_id,
// 			email: bookingData.booking.customer.mail,
// 			total_nights: bookingData.booking.services[0].nights,
// 			total_amount: bookingData.booking.services[0].total_price,
// 			check_in: bookingData.booking.arrival_date,
// 			check_out: bookingData.booking.departure_date,
// 			guests: `${bookingData.booking.services[0].persons} adults`,
// 			cancellation_policy: bookingData.booking.currency,
// 			room_name: bookingData.booking.meta?.rooms?.attributes.title,
// 			room_price: bookingData.booking.services[0].total_price,
// 			extra: bookingData.booking.meta?.extra,
// 			vat: 130.84,
// 		},
// 		recipients: [
// 			{ address: `${bookingData.booking.customer.mail}` },
// 		],
// 	})
// 	return responce
// }