import { combineReducers } from "redux";
import { propertyReducer } from "./property";
import { roomsReducer } from "./rooms";
import { bookingReducer } from "./booking";
import { propertiesReducer } from "./properties";
import { hotelBySlugReducer } from "./hotelBySlug";

export const rootReducer = combineReducers({
  properties: propertiesReducer,
  property: propertyReducer,
  rooms: roomsReducer,
  booking: bookingReducer,
  hotelBySlug: hotelBySlugReducer,
});
