import React, { FormEvent } from "react";
import Button from "../../ui/Button/Button";

import styles from "./cancel.module.scss";
import { useAppSelector } from "../../hooks/redux";

const Cancel = () => {
  const { hotel } = useAppSelector((store) => store.hotelBySlug);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    console.log("Form submit");
  };

  return (
    <main className={styles.content}>
      <h1
        className={styles.title}
        style={{
          color:
            hotel?.secondary_color !== "#ffffff" ? hotel?.secondary_color : "",
        }}
      >
        Welcome back!
      </h1>
      <p
        className={styles.text}
        style={{
          color: hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
        }}
      >
        Please enter your confirmation number and your last name.
      </p>
      <form onSubmit={handleSubmit}>
        <div className={styles.fields}>
          <div className={styles.field}>
            <div
              className={styles.input}
              style={{
                background:
                  hotel?.fifth_color !== "#ffffff" ? hotel?.fifth_color : "",
              }}
            >
              <label htmlFor="number" hidden>
                Number
              </label>
              <input
                type="text"
                name="number"
                id="number"
                placeholder="Confirmation Number:"
                style={{
                  color:
                    hotel?.secondary_color !== "#ffffff"
                      ? hotel?.secondary_color
                      : "",
                }}
              />
            </div>
            <span className={styles.asterisk}>*</span>
          </div>
          <div className={styles.field}>
            <div
              className={styles.input}
              style={{
                background:
                  hotel?.fifth_color !== "#ffffff" ? hotel?.fifth_color : "",
              }}
            >
              <label htmlFor="name" hidden>
                Last Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Last Name:"
                style={{
                  color:
                    hotel?.secondary_color !== "#ffffff"
                      ? hotel?.secondary_color
                      : "",
                }}
              />
            </div>
            <span className={styles.asterisk}>*</span>
          </div>
        </div>
        <p className={styles.note}>*= Mandatory Fields</p>
        <div className={styles.row}>
          <Button
            buttonClass={styles.button}
            containerStyles={{
              background:
                hotel?.secondary_color !== "#ffffff"
                  ? hotel?.secondary_color
                  : "",
              color: hotel?.third_color !== "#ffffff" ? hotel?.third_color : "",
            }}
            title="SUBMIT"
          />
        </div>
      </form>
    </main>
  );
};

export default Cancel;
