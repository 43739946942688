import { API2_OPSERV, SPARKPOST_API_KEY } from '../../utils/constants'
import { AppDispatch, IBookingData } from '../../utils/types'

export const SET_NOTIFICATION_REQUEST: 'SET_NOTIFICATION_REQUEST' =
	'SET_NOTIFICATION_REQUEST'
export const SET_NOTIFICATION_SUCCESS: 'SET_NOTIFICATION_SUCCESS' =
	'SET_NOTIFICATION_SUCCESS'
export const SET_NOTIFICATION_FAILED: 'SET_NOTIFICATION_FAILED' =
	'SET_NOTIFICATION_FAILED'
export const SET_HOTEL_NOTIFICATION_REQUEST: 'SET_HOTEL_NOTIFICATION_REQUEST' =
	'SET_HOTEL_NOTIFICATION_REQUEST'
export const SET_HOTEL_NOTIFICATION_SUCCESS: 'SET_HOTEL_NOTIFICATION_SUCCESS' =
	'SET_HOTEL_NOTIFICATION_SUCCESS'
export const SET_HOTEL_NOTIFICATION_FAILED: 'SET_HOTEL_NOTIFICATION_FAILED' =
	'SET_HOTEL_NOTIFICATION_FAILED'
export const SET_CLIENT_NOTIFICATION_REQUEST: 'SET_HOTEL_NOTIFICATION_REQUEST' =
	'SET_HOTEL_NOTIFICATION_REQUEST'
export const SET_CLIENT_NOTIFICATION_SUCCESS: 'SET_CLIENT_NOTIFICATION_SUCCESS' =
	'SET_CLIENT_NOTIFICATION_SUCCESS'
export const SET_CLIENT_NOTIFICATION_FAILED: 'SET_CLIENT_NOTIFICATION_FAILED' =
	'SET_CLIENT_NOTIFICATION_FAILED'

export const sendNotivication =
	(data: IBookingData) => (dispatch: AppDispatch) => {
		dispatch({ type: SET_NOTIFICATION_REQUEST })

		// 'http://localhost:3001/send-notification'- proxy server url
		return fetch(`${API2_OPSERV}/spark/send-notification`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				hotelData: {
					hotel_name: data.booking.meta?.hotel?.title,
					reservation_number: data.booking.reservation_id,
					recipient_name: `${data.booking.customer.name} ${data.booking.customer.surname}`,
					email: data.booking.meta?.hotel?.email,
					total_nights: data.booking.services[0].nights,
					total_amount: data.booking.meta?.totalPrice,
					nights: data.booking.services[0].nights,
					check_in: data.booking.arrival_date,
					check_out: data.booking.departure_date,
					guests: `${data.booking.services[0].persons} adults`,
					cancellation_policy: data.booking.currency,
					room_name: data.booking.meta?.rooms?.attributes.title,
					room_price: data.booking.services[0].total_price,
					extra: data.booking.meta?.extra,
					vat: 130.84,
				},
				clientData: {
					recipient_name: `${data.booking.customer.name} ${data.booking.customer.surname}`,
					hotel_name: data.booking.meta?.hotel?.title,
					reservation_number: data.booking.reservation_id,
					email: data.booking.customer.mail,
					total_nights: data.booking.services[0].nights,
					total_amount: data.booking.meta?.totalPrice,
					nights: data.booking.services[0].nights,
					check_in: data.booking.arrival_date,
					check_out: data.booking.departure_date,
					guests: `${data.booking.services[0].persons} adults`,
					cancellation_policy: data.booking.currency,
					room_name: data.booking.meta?.rooms?.attributes.title,
					room_price: data.booking.services[0].total_price,
					extra: data.booking.meta?.extra,
					vat: 130.84,
				},
			}),
		})
			.then(response => response.json())
			.then(responseData => {
				dispatch({ type: SET_NOTIFICATION_SUCCESS })
				console.log('Notification response:', responseData)

				if (Array.isArray(responseData) && responseData.length === 2) {
					responseData.forEach((notification, index) => {
						const notificationType = index === 0 ? 'Hotel' : 'Client'

						if (notification.results) {
							const { total_accepted_recipients, total_rejected_recipients } =
								notification.results

							if (
								total_accepted_recipients > 0 &&
								total_rejected_recipients === 0
							) {
								console.log(
									`${notificationType} notification sent successfully:`,
									notification.results
								)
							} else {
								console.warn(
									`${notificationType} notification failed to send:`,
									notification.results
								)
							}
						} else {
							console.error(
								`No results found in ${notificationType} notification response:`,
								notification
							)
						}
					})
				} else {
					console.error('Unexpected response format:', responseData)
				}
			})
			.catch(error => {
				dispatch({ type: SET_NOTIFICATION_FAILED })
				console.error('Error sending notifications:', error)
			})
	}