import {GET_PROPERTY_REQUEST, GET_PROPERTY_SUCCESS, GET_PROPERTY_FAILED, GET_PROPERTY_CLOSED} from "../actions/property";
import {IPropertyState, TPropertyActions} from "../types/property";

export const initialState: IPropertyState = {
  id: null,
  title: null,
  country: null,
  currency: null,
  policy: null,
  closed: [],
  request: false,
  failed: false,
}

export const propertyReducer = (state: IPropertyState = initialState, action: TPropertyActions) => {
  switch (action.type) {
    case GET_PROPERTY_REQUEST: {
      return {
        ...state,
        request: true,
        failed: false
      };
    }
    case GET_PROPERTY_SUCCESS: {
      return {
        ...state,
        request: false,
        id: action.id,
        title: action.title,
        currency: action.currency,
        country: action.country,
        policy: action.policy,
        failed: false
      };
    }
    case GET_PROPERTY_FAILED: {
      return {
        ...state,
        request: false,
        failed: true
      };
    }
    case GET_PROPERTY_CLOSED: {
      return {
        ...state,
        closed: action.closed
      };
    }
    default: {
      return state;
    }
  }
}