import { AppDispatch, IBookingData, Info, TDates } from "../../utils/types";
import { apiRequest } from "../../utils/api";
import {
  API_BOOKING_URL,
  API_KEY,
  CHANNEL_NAME,
  PROPERTY_ID,
} from "../../utils/constants";
import { EXTERNAL_ID } from "../../App";
import { setStripeCheckoutSession } from './stripeCheckout';
import { sendNotivication } from './notification';
import { resolve } from 'path';

export const SET_BOOKING_DATES: "SET_BOOKING_DATES" = "SET_BOOKING_DATES";
export const SET_BOOKING_ADULTS: "SET_BOOKING_ADULTS" = "SET_BOOKING_ADULTS";
export const SET_BOOKING_CHILDREN: "SET_BOOKING_CHILDREN" =
  "SET_BOOKING_CHILDREN";
export const SET_BOOKING_ROOMS_COUNT: "SET_BOOKING_ROOMS_COUNT" =
  "SET_BOOKING_ROOMS_COUNT";
export const SET_BOOKING_ROOM_ID: "SET_BOOKING_ROOM_ID" = "SET_BOOKING_ROOM_ID";
export const SET_BOOKING_RATE_ID: "SET_BOOKING_RATE_ID" = "SET_BOOKING_RATE_ID";
export const SET_BOOKING_TOTAL_PRICE: "SET_BOOKING_TOTAL_PRICE" =
  "SET_BOOKING_TOTAL_PRICE";
export const SET_BOOKING_TOTAL_PRICE_SALE: "SET_BOOKING_TOTAL_PRICE_SALE" =
  "SET_BOOKING_TOTAL_PRICE_SALE";
export const SET_BOOKING_RESET: "SET_BOOKING_RESET" = "SET_BOOKING_RESET";
export const SET_BOOKING_EXTRA: "SET_BOOKING_EXTRA" = "SET_BOOKING_EXTRA";
export const SET_BOOKING_REQUEST: "SET_BOOKING_REQUEST" = "SET_BOOKING_REQUEST";
export const SET_BOOKING_SUCCESS: "SET_BOOKING_SUCCESS" = "SET_BOOKING_SUCCESS";
export const SET_BOOKING_FAILED: "SET_BOOKING_FAILED" = "SET_BOOKING_FAILED";
export const SET_BOOKING_PROMOCODE: "SET_BOOKING_PROMOCODE" =
  "SET_BOOKING_PROMOCODE";
export const SET_BOOKING_PROMOCODE_ISDISABLED: "SET_BOOKING_PROMOCODE_ISDISABLED" =
  "SET_BOOKING_PROMOCODE_ISDISABLED";
export const SET_BOOKING_ROOMCHACKED: "SET_BOOKING_ROOMCHACKED" =
  "SET_BOOKING_ROOMCHACKED";


export const setAdults = (adults: Info) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_BOOKING_ADULTS, adults: Number(adults) });
};

export const setChildren = (children: Info) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_BOOKING_CHILDREN, children: Number(children) });
};

export const setDates = (dates: TDates) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_BOOKING_DATES, dates });
  // dispatch({ type: SET_BOOKING_SUCCESS, data: null });
};

export const setRoomsCount = (count: Info) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_BOOKING_ROOMS_COUNT, count });
};

export const setRoomId = (id: Info) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_BOOKING_ROOM_ID, id });
};

export const setRateId = (id: Info) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_BOOKING_RATE_ID, id });
};

export const setTotalPrice = (price: number) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_BOOKING_TOTAL_PRICE, price });
};

export const setTotalPriceSale = (price: number) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_BOOKING_TOTAL_PRICE_SALE, price });
};

export const setExtraMeta =
  (extra: { title: string; price: number }[]) => (dispatch: AppDispatch) => {
    dispatch({ type: SET_BOOKING_EXTRA, extra });
  };

export const resetBooking = () => (dispatch: AppDispatch) => {
  dispatch({ type: SET_BOOKING_RESET });
};

export const setPromocode = (promocode: string) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_BOOKING_PROMOCODE, promocode });
};

export const setIsDisabled =
  (isDisabled: boolean) => (dispatch: AppDispatch) => {
    dispatch({ type: SET_BOOKING_PROMOCODE_ISDISABLED, isDisabled });
  };

export const setRoomChacked =
  (roomChacked: number) => (dispatch: AppDispatch) => {
    dispatch({ type: SET_BOOKING_ROOMCHACKED, roomChacked });
  };

export const setBookingRequest =
	(data: IBookingData) => (dispatch: AppDispatch) => {
		dispatch({ type: SET_BOOKING_REQUEST })
			apiRequest(
			  // `${API_BOOKING_URL}/${CHANNEL_NAME}/${PROPERTY_ID}/push_booking`,
			  `${API_BOOKING_URL}/${CHANNEL_NAME}/${EXTERNAL_ID}/push_booking`,
			  {
			    method: 'POST',
			    mode: 'cors',
			    cache: 'no-cache',
			    credentials: 'same-origin',
			    headers: {
			      'Content-Type': 'application/json',
			      'api-key': API_KEY,
			    },
			    redirect: 'follow',
			    referrerPolicy: 'no-referrer',
			    body: JSON.stringify(data),
			  }
			)
			.then(response => {
				console.log('response+: ', response)
				if (response.data) {
					dispatch({ type: SET_BOOKING_SUCCESS, data: response.data })
					dispatch({ type: SET_BOOKING_RESET })

				  return dispatch(sendNotivication(data))

				} else {
					throw new Error('No response data')
				}
			})
			.then(() => {
				return dispatch(setStripeCheckoutSession(data.booking.customer.mail))
			})
			.then(() => {
				console.log('Stripe checkout session set successfully')
			})
			.catch(err => {
				console.log('err: ', err)
				dispatch({ type: SET_BOOKING_FAILED })
			})
	}