import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import {
  Info,
  IRatePlans,
  IRoomData,
  IRoomSpaces,
  ISelectOption,
} from "../../utils/types";
import Select from "../../ui/Select/Select";

import roomImage from "../../assets/images/room-image.jpg";

import styles from "./room.module.scss";

import user from "../../assets/images/user.png";
import { useWindowWidth } from "../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import {
  setRateId,
  setRoomChacked,
  setRoomId,
  setRoomsCount,
  setTotalPrice,
} from "../../services/actions/booking";
import RatePlan from "../RatePlan/RatePlan";
import Spaces from "../Spaces/Spaces";

const Room = (props: IRoomData) => {
  const { hotel } = useAppSelector((store) => store.hotelBySlug);
  const { promocode } = useAppSelector((store) => store.booking);
  const dispatch = useAppDispatch();
  const width: number | undefined = useWindowWidth();
  const navigate: NavigateFunction = useNavigate();
  let { id } = useParams();
  const [count, setCount] = useState<number>(0);
  const [checked, setChecked] = useState<Info>(0);
  const [planRate, setPlanRate] = useState<string | null>(null);

  const { rate_plans } = props.attributes;

  const roomsCountOptions = useCallback(() => {
    if (rate_plans.length !== 0) {
      const options: ISelectOption[] = [];
      for (let i: number = 0; i <= rate_plans[0].availability; i++) {
        options.push({
          value: i,
          label: i,
        });
      }
      return options;
    }
  }, [rate_plans]);

  const changeRooms = (el: Info) => setCount(Number(el));

  const handleSetChecked = (el: Info, id: string | null) => {
    setChecked(el);
    setPlanRate(id);
  };

  const setBookingData = (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(setRoomId(props.id));
    dispatch(setRateId(planRate));
    dispatch(setRoomsCount(count));
    dispatch(setRoomChacked(parseInt(checked as string)));
    dispatch(setTotalPrice(count * parseInt(checked as string)));
    console.log(
      "Submit",
      "\nRoom ID: ",
      props.id,
      "\nRate ID :",
      planRate,
      "\nRooms count: ",
      count
    );

    navigate(`/${id}/booking/total`);
  };

  const displayDiscount = (total_price: number) => {
    console.log("total_price", total_price);
    if (total_price === 0) {
      return "0";
    }
    if (!total_price) return "";

    switch (hotel?.promocodes[0]?.discount_type) {
      case "percent": {
        const precent = Math.ceil(
          total_price - (total_price / 100) * hotel?.promocodes[0]?.discount
        );
        return precent.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      case "sum": {
        const sum = total_price - hotel?.promocodes[0]?.discount;
        return sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  };

  useEffect(() => {
    if (rate_plans.length !== 0) {
      setChecked(rate_plans[0].total_price);
      setPlanRate(rate_plans[0].id);
    }
  }, []);

  return (
    <form className={styles.room} onSubmit={setBookingData}>
      {promocode && (
        <div
          className={styles.sale}
          style={{
            background:
              hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            color: hotel?.fifth_color !== "#ffffff" ? hotel?.fifth_color : "",
          }}
        >
          Your unlocked offer will be displayed at the checkout
        </div>
      )}

      <div
        className={styles.content}
        style={{
          background:
            hotel?.fourth_color !== "#ffffff" ? hotel?.fourth_color : "",
        }}
      >
        <div className={styles.preview}>
          {props.attributes.photos[0]?.url ? (
            <img
              className={styles.image}
              src={props.attributes.photos[0].url}
              alt={props.attributes.title}
            />
          ) : (
            <img
              className={styles.image}
              src={roomImage}
              alt={props.attributes.title}
            />
          )}
        </div>
        <div
          className={styles.panel}
          style={{
            background:
              hotel?.secondary_color !== "#ffffff"
                ? hotel?.secondary_color
                : "",
          }}
        >
          {width && width < 1240 && rate_plans.length !== 0 && (
            <div className={styles.variants}>
              <Select
                selectClass={styles.select}
                textClass={styles.selected}
                listClass={styles.list}
                optionClass={styles.option}
                optionsList={rate_plans.map((item: IRatePlans) => {
                  return { value: item.total_price, label: item.title };
                })}
                defaultText={rate_plans[0].title}
                isRoom={true}
              />
            </div>
          )}
          {rate_plans.length !== 0 ? (
            <>
              <div className={styles.count}>
                <Select
                  selectClass={styles.select}
                  textClass={styles.selected}
                  listClass={styles.list}
                  optionClass={styles.option}
                  optionsList={roomsCountOptions()}
                  onChange={changeRooms}
                  defaultText="0"
                  isRoom={true}
                />
                <span
                  className={styles.countText}
                  style={{
                    color:
                      hotel?.third_color !== "#ffffff"
                        ? hotel?.third_color
                        : "",
                  }}
                >
                  Room
                </span>
              </div>
              <div className={styles.total}>
                {promocode && (
                  <div className={styles.totalWrapperTop}>
                    <span className={styles.totalCurrentTop}>
                      {count === 0
                        ? parseInt(checked as string)
                        : count * parseInt(checked as string)}{" "}
                      {rate_plans[0].currency}
                    </span>
                  </div>
                )}

                <div className={styles.totalWrapperBottom}>
                  <span
                    className={styles.totalCurrent}
                    style={{
                      color:
                        hotel?.third_color !== "#ffffff"
                          ? hotel?.third_color
                          : "",
                    }}
                  >
                    {count === 0 && promocode
                      ? displayDiscount(parseInt(checked as string))
                      : count === 0
                      ? parseInt(checked as string)
                      : promocode
                      ? displayDiscount(count * parseInt(checked as string))
                      : count * parseInt(checked as string)}{" "}
                    {rate_plans[0].currency}
                  </span>
                </div>
              </div>
              <button
                className={styles.book}
                disabled={count === 0}
                style={{
                  color:
                    hotel?.secondary_color !== "#ffffff"
                      ? hotel?.secondary_color
                      : "",
                  background:
                    hotel?.primary_color !== "#ffffff"
                      ? hotel?.primary_color
                      : "",
                }}
              >
                {width && width >= 992 ? "Book Now" : "Book"}
              </button>
            </>
          ) : (
            <button
              className={styles.book}
              onClick={() => navigate(`/${id}/booking/dates`)}
              style={{
                color:
                  hotel?.secondary_color !== "#ffffff"
                    ? hotel?.secondary_color
                    : "",
                background:
                  hotel?.primary_color !== "#ffffff"
                    ? hotel?.primary_color
                    : "",
              }}
            >
              Book Now
            </button>
          )}
        </div>
        <div className={styles.info}>
          <h2
            className={styles.title}
            style={{
              color:
                hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            }}
          >
            {props.attributes.title}
          </h2>
          <ul className={styles.options}>
            {rate_plans.length !== 0 &&
              rate_plans[0].occupancy.adults !== 0 && (
                <li
                  className={styles.item}
                  style={{
                    color:
                      hotel?.secondary_color !== "#ffffff"
                        ? hotel?.secondary_color
                        : "",
                  }}
                >
                  <img
                    className={styles.icon}
                    style={{
                      color:
                        hotel?.secondary_color !== "#ffffff"
                          ? hotel?.secondary_color
                          : "",
                    }}
                    src={user}
                    alt=""
                  />
                  {rate_plans[0].occupancy.adults} people maximum per room
                </li>
              )}
            {props.attributes.spaces.length !== 0 &&
              props.attributes.spaces.map(
                (space: IRoomSpaces, index: number) => (
                  <Spaces
                    {...space}
                    key={("space-" + space.type + "-" + index).toString()}
                  />
                )
              )}
          </ul>
          {props.attributes.description && (
            <p
              className={styles.description}
              style={{
                color:
                  hotel?.secondary_color !== "#ffffff"
                    ? hotel?.secondary_color
                    : "",
              }}
            >
              {props.attributes.description}
            </p>
          )}
          {width && width < 1240 && rate_plans.length === 0 && (
            <div
              className={styles.empty}
              style={{
                color:
                  hotel?.secondary_color !== "#ffffff"
                    ? hotel?.secondary_color
                    : "",
              }}
            >
              Unfortunately, there is no availability on the selected dates.
              Please select an alternative date.
            </div>
          )}
        </div>
        {width && width >= 1240 && (
          <div className={styles.variants}>
            {rate_plans.length !== 0 ? (
              rate_plans.map((item: IRatePlans) => (
                <RatePlan
                  item={item}
                  checked={checked}
                  onChange={handleSetChecked}
                  key={item.id.toString()}
                />
              ))
            ) : (
              <div
                className={styles.empty}
                style={{
                  color:
                    hotel?.secondary_color !== "#ffffff"
                      ? hotel?.secondary_color
                      : "",
                }}
              >
                Unfortunately, there is no availability on the selected dates.
                Please select an alternative date.
              </div>
            )}
          </div>
        )}
      </div>
    </form>
  );
};

export default Room;
