import React from "react";
import { Route, Routes } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Guests from "../Guests/Guests";
import Dates from "../Dates/Dates";
import Accommodations from "../Accommodations/Accommodations";
import Total from "../Total/Total";
import Cancel from "../Cancel/Cancel";
import Reservation from "../Reservation/Reservation";

import styles from "./Booking.module.scss";

const Booking = () => {
  return (
    <div className={styles.container}>
      <Header />
      <Routes>
        <Route path="guests" element={<Guests />} />
        <Route path="cancel" element={<Cancel />} />
        <Route path="dates" element={<Dates />} />
        <Route path="accommodations" element={<Accommodations />} />
        <Route path="total" element={<Total />} />
        <Route path="total/reservation" element={<Reservation />} />
        <Route path="currency" element={<p>change-currency</p>} />
      </Routes>
      <Footer />
    </div>
  );
};

export default Booking;
