import React, { useEffect, useMemo, useState } from "react";

import styles from "./total.module.scss";
import Button from "../../ui/Button/Button";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { IExtra, IRatePlans, IRoomData } from "../../utils/types";
import Loader from "../../ui/Loader/Loader";
import Extra from "../../components/Extra/Extra";
import { setExtraMeta, setTotalPrice } from "../../services/actions/booking";
import { Extras } from "../../services/types/hotelBySlug";

const Total = () => {
  const dispatch = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();
  let { id } = useParams();
  const { currency } = useAppSelector((store) => store.property);
  const { rooms, request, failed } = useAppSelector((store) => store.rooms);
  const {
    roomID,
    roomsCount,
    rateID,
    totalPrice,
    extra,
    adults,
    children,
    dates,
  } = useAppSelector((store) => store.booking);
  const { hotel } = useAppSelector((store) => store.hotelBySlug);
  const [meta, setMeta] = useState<
    Array<{
      title: string;
      price: number;
      checked: boolean;
      id: number;
      price_type: string;
      price_type_display: string;
    }>
  >(extra);

  const ratePlan: IRatePlans | undefined = useMemo(() => {
    const room: IRoomData | undefined =
      rooms && rooms.find((room: IRoomData) => room.id === roomID);
    const rate: IRatePlans | undefined =
      room &&
      room.attributes.rate_plans.find((rate: IRatePlans) => rate.id === rateID);
    return rate;
  }, [rooms, rateID, roomID]);

  useEffect(() => {
    const price: number = Number(ratePlan?.total_price) * roomsCount;
    // const price: number = 0; //

    const total: number = meta.reduce(
      (
        sum: number,
        current: {
          title: string;
          price: number;
          price_type: string;
          price_type_display: string;
        }
      ) => {
        if (current.price_type === "per_booking") {
          return sum + current.price;
        } else {
          const adultsCount = adults + children;
          const firstDate = dates[0].getTime();
          const lastDate = dates[1].getTime();
          const amountOfDays = Math.ceil(
            (lastDate - firstDate) / (1000 * 3600 * 24)
          );
          const amountOfNight =
            amountOfDays === 1 ? amountOfDays : amountOfDays - 1;
          return (sum + current.price) * adultsCount * amountOfNight;
        }
      },
      price
    );
    console.log("total: ", total);
    dispatch(setTotalPrice(total));
  }, [dispatch, ratePlan, roomsCount, meta, adults, children, dates]);

  const addExtra = (
    item: {
      title: string;
      price: number;
      price_type: string;
      id: number;
      price_type_display: string;
    },
    checked?: boolean
  ) => {
    setMeta(
      !checked
        ? [
            ...meta,
            {
              title: item.title,
              price: item.price,
              checked: true,
              id: item.id,
              price_type: item.price_type,
              price_type_display: item.price_type_display,
            },
          ]
        : [...meta].filter(
            (ex: {
              title: string;
              price: number;
              id: number;
              price_type: string;
              price_type_display: string;
            }) => ex.id !== item.id
          )
    );
  };

  const handleSummary = () => {
    navigate(`/${id}/booking/total/reservation`);
  };

  useEffect(() => {
    dispatch(setExtraMeta(meta));
  }, [meta, dispatch]);

  // useEffect(() => {
  //   dispatch(setTotalPrice(1768));
  // }, [dispatch]);

  return request || failed ? (
    <main className={styles.main}>
      <Loader request={request} />
    </main>
  ) : (
    <>
      <main className={styles.main}>
        <div className={styles.container}>
          <h1
            className={styles.title}
            style={{
              color:
                hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            }}
          >
            Add Extra
          </h1>
          <ul className={styles.items}>
            {hotel?.extras &&
              hotel.extras.map((item: Extras, index: number) => (
                <Extra
                  props={item}
                  key={("extra-" + index).toString()}
                  onClick={addExtra}
                />
              ))}
          </ul>
          <div className={styles.button}>
            <Button
              title="VIEW SUMMARY"
              onChange={handleSummary}
              buttonClass={styles.view}
              containerStyles={{
                background:
                  hotel?.secondary_color !== "#ffffff"
                    ? hotel?.secondary_color
                    : "",
                color:
                  hotel?.third_color !== "#ffffff" ? hotel?.third_color : "",
              }}
            />
          </div>
        </div>
      </main>
      <div
        className={styles.total}
        style={{
          background:
            hotel?.fourth_color !== "#ffffff" ? hotel?.fourth_color : "",
        }}
      >
        <div className={styles.container}>
          <div className={styles.result}>
            <div className={styles.selection}>
              <span
                className={styles.rooms}
                style={{
                  color:
                    hotel?.primary_color !== "#ffffff"
                      ? hotel?.primary_color
                      : "",
                }}
              >{`${roomsCount} ${roomsCount === 1 ? "Room" : "Rooms"}`}</span>
              <span
                className={styles.extra}
                style={{
                  color:
                    hotel?.primary_color !== "#ffffff"
                      ? hotel?.primary_color
                      : "",
                }}
              >
                {`${extra.length}`} Extra
              </span>
            </div>
            <div className={styles.price}>
              <span
                className={styles.text}
                style={{
                  color:
                    hotel?.primary_color !== "#ffffff"
                      ? hotel?.primary_color
                      : "",
                }}
              >
                Total Price
              </span>
              <span
                className={styles.value}
                style={{
                  color:
                    hotel?.secondary_color !== "#ffffff"
                      ? hotel?.secondary_color
                      : "",
                }}
              >{`${currency} ${totalPrice ? totalPrice : 0}`}</span>
            </div>
            <p
              className={`${styles.clear} ${
                meta.length === 0 ? styles.disabled : ""
              }`}
              onClick={() => setMeta([])}
              style={{
                color:
                  hotel?.primary_color !== "#ffffff"
                    ? hotel?.primary_color
                    : "",
              }}
            >
              Clear Selection
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Total;
