import React from "react";
import styles from "../NotFound/not-found.module.scss";
import logo from "../../assets/images/logo.png";
import { useAppSelector } from "../../hooks/redux";
import Loader from "../../ui/Loader/Loader";

const NotFound = () => {
  const { hotel, request, failed } = useAppSelector(
    (store) => store.hotelBySlug
  );
  return (
    <>
      {request || failed ? (
        <Loader request={request} />
      ) : (
        <div className={styles.container}>
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
          </div>
          <h1
            className={styles.title}
            style={{
              color:
                hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            }}
          >
            Hotel Not Found.
          </h1>
          <p
            className={styles.text}
            style={{
              color:
                hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
            }}
          >
            Please, check hotel ID in address url.
          </p>
        </div>
      )}
    </>
  );
};

export default NotFound;
