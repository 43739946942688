import { motion } from "framer-motion";
import {FC} from "react";
import {IModalOverlay} from "../../utils/types";
import styles from './modal-overlay.module.scss';

const ModalOverlay: FC<IModalOverlay> = ({onClose}) => {
  return (
    <motion.div
      initial={{ opacity: 0,}}
      animate={{ opacity: 1,}}
      exit={{ opacity: 0}}
      transition={{duration: 0.5}}
      className={styles.backdrop}
      onClick={onClose}>
    </motion.div>
  );
}

export default ModalOverlay;