import { AppDispatch } from "../../utils/types";
import { apiRequest, getOptions } from "../../utils/api";
import { API_URL, CHANNEL_NAME } from "../../utils/constants";

export const GET_PROPERTIES_REQUEST: "GET_PROPERTIES_REQUEST" =
  "GET_PROPERTIES_REQUEST";
export const GET_PROPERTIES_SUCCESS: "GET_PROPERTIES_SUCCESS" =
  "GET_PROPERTIES_SUCCESS";
export const GET_PROPERTIES_FAILED: "GET_PROPERTIES_FAILED" =
  "GET_PROPERTIES_FAILED";

export const getProperties = () => (dispatch: AppDispatch) => {
  dispatch({ type: GET_PROPERTIES_REQUEST });
  apiRequest(`${API_URL}/${CHANNEL_NAME}/property_list`, getOptions)
    .then((response) => {
      if (response) {
        dispatch({
          type: GET_PROPERTIES_SUCCESS,
          ids: response.data.map((item: any) => item.id),
        });
      }
    })
    .catch((err) => {
      console.log("err: ", err);
      dispatch({ type: GET_PROPERTIES_FAILED });
    });
};
