import { AppDispatch } from "../../utils/types";
import { apiRequest, getOptions } from "../../utils/api";
import { API_URL, CHANNEL_NAME, PROPERTY_ID } from "../../utils/constants";

export const GET_PROPERTY_REQUEST: "GET_PROPERTY_REQUEST" =
  "GET_PROPERTY_REQUEST";
export const GET_PROPERTY_SUCCESS: "GET_PROPERTY_SUCCESS" =
  "GET_PROPERTY_SUCCESS";
export const GET_PROPERTY_FAILED: "GET_PROPERTY_FAILED" = "GET_PROPERTY_FAILED";
export const GET_PROPERTY_CLOSED: "GET_PROPERTY_CLOSED" = "GET_PROPERTY_CLOSED";

export const getProperty = (propID: string) => (dispatch: AppDispatch) => {
  console.log("ji");
  dispatch({ type: GET_PROPERTY_REQUEST });
  apiRequest(`${API_URL}/${CHANNEL_NAME}/${propID}/property_info`, getOptions)
    .then((response) => {
      console.log("success");
      if (response) {
        console.log("response prop: ", response);
        dispatch({
          type: GET_PROPERTY_SUCCESS,
          id: response.data.id,
          currency: response.data.attributes.currency,
          title: response.data.attributes.title,
          country: response.data.attributes.country,
          policy: response.data.attributes.hotel_policy,
        });
      }
    })
    .catch((err) => {
      console.log("err: ", err);
      dispatch({ type: GET_PROPERTY_FAILED });
    });
};

export const getClosedDays = (propID: string) => (dispatch: AppDispatch) => {
  apiRequest(`${API_URL}/${CHANNEL_NAME}/${propID}/closed_dates`, getOptions)
    .then((response) => {
      if (response) {
        dispatch({
          type: GET_PROPERTY_CLOSED,
          closed: response.data.attributes.closed,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
