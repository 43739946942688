import React, {
  ChangeEvent,
  FormEvent,
  MouseEventHandler,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import Select, {
  DropdownIndicatorProps,
  components,
  SingleValue,
} from "react-select";

import styles from "./reservation.module.scss";
import Button from "../../ui/Button/Button";
import {
  CountryOption,
  IBookingData,
  IRatePlans,
  IRoomData,
  ITotalForm,
  ITotalFormErrors,
} from "../../utils/types";
import { Link } from "react-router-dom";
import Order from "../../components/Order/Order";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { colourStyles, dateFormat, numberWithCommas } from "../../utils/helpers";
import {
  setBookingRequest,
  setIsDisabled,
  setPromocode,
  setTotalPrice,
  setTotalPriceSale,
} from "../../services/actions/booking";
import { v4 as uuidv4 } from "uuid";
import Loader from "../../ui/Loader/Loader";
import Modal from "../../components/Modal/Modal";
import { AnimatePresence } from "framer-motion";
import ReservationTerms from "./ReservationTerms";
import ReservationPolicy from "./ReservationPolicy";

const DropdownIndicator = (
  props: DropdownIndicatorProps<CountryOption, false>
) => {
  const { menuIsOpen } = props.selectProps;
  const caretClass = menuIsOpen
    ? `${styles.caret} ${styles.active}`
    : `${styles.caret}`;
  return (
    <components.DropdownIndicator {...props}>
      <div className={caretClass}></div>
    </components.DropdownIndicator>
  );
};

const Reservation = () => {
  const dispatch = useAppDispatch();
  const [openTerms, setOpenTerms] = useState<boolean>(false);
  const [openPolicy, setOpenPolicy] = useState<boolean>(false);
  const { currency, policy } = useAppSelector((store) => store.property);
  const { rooms } = useAppSelector((store) => store.rooms);
  const { request, failed, data, promocode, isDisabled } = useAppSelector(
    (store) => store.booking
  );
  const {
    adults,
    children,
    dates,
    roomID,
    rateID,
    extra,
    totalPrice,
    totalPriceSale,
    roomChacked,
  } = useAppSelector((store) => store.booking);
  const { hotel } = useAppSelector((store) => store.hotelBySlug);
  const textRef = useRef<HTMLTextAreaElement>(null);
  const [addressChecked, setAddressChecked] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(true);
  const [values, setValues] = useState<ITotalForm>({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    country: null,
    message: "",
    terms: false,
    promocode: "",
  });
  const [errors, setErrors] = useState<ITotalFormErrors>({
    firstname: false,
    lastname: false,
    email: false,
    country: false,
    promocode: false,
  });

  const room: IRoomData | undefined = useMemo(
    () => rooms.find((item: IRoomData) => item.id === roomID),
    [rooms, roomID]
  );
  const rate: IRatePlans | undefined = useMemo(
    () =>
      room &&
      room.attributes.rate_plans.find((item: IRatePlans) => item.id === rateID),
    [rateID]
  );

  countries.registerLocale(enLocale);
  const countryObj: countries.LocalizedCountryNames<{ select: "official" }> =
    countries.getNames("en", { select: "official" });
  const countryArr: readonly CountryOption[] = Object.entries(countryObj).map(
    ([key, value]): { label: string; value: string } => {
      return {
        label: value,
        value: key,
      };
    }
  );

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: !value });
  };

  const handleCountryChange = (country: SingleValue<CountryOption | null>) => {
    setValues({ ...values, country });
    setErrors({ ...errors, country: !country });
  };

  	const totalExtraNumber: number = useMemo(() => {
		return extra.reduce(
			(
				sum: number,
				current: { title: string; price: number; checked: boolean }
			) => sum + current.price,
			0
		)
	}, [extra])

  const handleFormSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      const { firstname, lastname, email, country, phone, message } = values;

      if (!firstname || !lastname || !email || !country) {
        setErrors({
          ...errors,
          firstname: !firstname,
          lastname: !lastname,
          email: !email,
          country: !country,
        });
      }

      if (!values.terms) {
        setChecked(false);
        return false;
      }

      const extrasData = extra.map((item) => {
        const adultsCount = adults + children;
        const firstDate = dates[0].getTime();
        const lastDate = dates[1].getTime();
        const amountOfDays = Math.ceil(
          (lastDate - firstDate) / (1000 * 3600 * 24)
        );
        const amountOfNight =
          amountOfDays === 1 ? amountOfDays : amountOfDays - 1;

        let totalPriceData: number;

        if (item.price_type === "per_booking") {
          totalPriceData = item.price;
        } else {
          totalPriceData = item.price * adultsCount * amountOfNight;
        }

        const itemData = {
			excluded: false,
			type: "Extra",
			total_price: String(totalPriceData),
			price_per_unit: String(item.price),
			price_mode: String(item.price_type_display),
			persons: adultsCount,
			nights: amountOfNight,
			name: String(item.title),
			room_index: 0,
			applicable_date: "",
        };
        return itemData;
      });

      const data: IBookingData = {
        booking: {
          status: "new",
          reservation_id: uuidv4(),
          arrival_date: dateFormat(dates[0]) ,
          departure_date: dateFormat(dates[1]),
          arrival_hour: "10:00",
          currency: rate?.currency,
          payment_collect: "property",
          payment_type: "credit_card",
          customer: {
            name: firstname,
            surname: lastname,
            country: country?.value,
            city: "",
            address: "",
            zip: "",
            mail: email,
            phone: phone ? phone : "",
            language: "",
            company: {
              title: "",
              number: "",
              number_type: "",
            },
            meta: {},
          },
          guarantee: {
            expiration_date: "10/2020",
            cvv: "123",
            cardholder_name: "Channex User",
            card_type: "visa",
            card_number: "4111111111111111",
            meta: {
              virtual_card_currency_code: "",
              virtual_card_current_balance: 0,
              virtual_card_decimal_places: 2,
              virtual_card_effective_date: "",
              virtual_card_expiration_date: "",
            },
          },
          rooms: [
            {
              index: 0,
              room_type_code: roomID,
              rate_plan_code: rateID,
              occupancy: {
                adults: adults,
                children: children,
                infants: 0,
              },
              meta: {},
            },
          ],
          services: extrasData,
          agent: {
            name: "Seller Agent Name",
            code: "",
            codeContext: "",
            phone: "",
            email: "",
            address: {
              addressLine: "",
              city: "",
              countryCode: "",
              stateCode: null,
              postCode: "",
            },
          },
          meta: {
            extra: totalExtraNumber,
            totalPrice,
            message,
            promocode,
            promocodes: hotel?.promocodes,
            rooms: room,
            roomChacked,
			hotel,
          },
        },
      };

	  console.log('go request data=: ', data);
	  console.log('go request data=: ', data.booking.arrival_date)
	  console.log('go request data=: ', data.booking.departure_date)
      dispatch(setBookingRequest(data)); //
    },
    [values, errors]
  );

  const handleInputTel = (e: SyntheticEvent<HTMLInputElement>): void => {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    target.value = target.value.replace(/\D/g, "").replace(/(\..*)\./g, "$1");
  };

  const handleOpenTerms = (e: SyntheticEvent) => {
    e.preventDefault();
    setOpenTerms(true);
  };

  const handleCloseTerms = () => setOpenTerms(false);

  const handleOpenPolicy = (e: SyntheticEvent) => {
    e.preventDefault();
    setOpenPolicy(true);
  };

  const handleClosePolicy = () => setOpenPolicy(false);

  const totalExtra: string = useMemo(() => {
    return numberWithCommas(
      extra.reduce(
        (
          sum: number,
          current: { title: string; price: number; checked: boolean }
        ) => sum + current.price,
        0
      )
    );
  }, [extra]);

  	// const totalExtraNumber: number = useMemo(() => {
	// 	return extra.reduce(
	// 		(
	// 			sum: number,
	// 			current: { title: string; price: number; checked: boolean }
	// 		) => sum + current.price,
	// 		0
	// 	)
		
	// }, [extra])

  useEffect(() => {
    const text: HTMLTextAreaElement | null = textRef.current;
    console.log(policy);
    if (text) {
      const autoHeight = () => {
        text.style.height = "auto";
        text.style.height = text.scrollHeight + "px";
      };

      text.style.height = text.scrollHeight + "px";
      text.classList.add("auto");

      text.addEventListener("input", autoHeight);

      return () => {
        text.removeEventListener("input", autoHeight);
      };
    }
  }, []);

  const applyPromoCode = (e: FormEvent) => {
    e.preventDefault();
    const { promocode: promocodeChange } = values;

    if (!promocodeChange) {
      setErrors({
        ...errors,
        promocode: !promocodeChange,
      });
    }

    if (promocodeChange === hotel?.promocodes[0]?.code) {
      dispatch(setPromocode(promocodeChange));
      dispatch(setIsDisabled(true));
    } else {
      setErrors({
        ...errors,
        promocode: promocodeChange !== hotel?.promocodes[0]?.code,
      });
    }
  };

  const removePromoCode = (e: FormEvent) => {
    e.preventDefault();
    dispatch(setPromocode(""));
    setValues({ ...values, promocode: "" });
    reApplyingDiscount();
  };

  const displayDiscount = (total_price: number) => {
    if (total_price === 0) {
      return "0";
    }
    if (!total_price) return "";

    switch (hotel?.promocodes[0]?.discount_type) {
      case "percent": {
        const precent = Math.ceil(
          total_price - (total_price / 100) * hotel?.promocodes[0]?.discount
        );
        return precent.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      case "sum": {
        const sum = total_price - hotel?.promocodes[0]?.discount;
        return sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  };

  const applyingDiscount = useCallback(() => {
    switch (hotel?.promocodes[0]?.discount_type) {
      case "percent": {
        const precent = rate?.total_price
			? Math.ceil(
				parseInt(totalExtra.replace(',', ''), 10) +
					(+rate?.total_price -
						(+rate?.total_price / 100) *
							hotel?.promocodes[0]?.discount)
				)
			: 0

        dispatch(setTotalPriceSale(totalPrice));
        dispatch(setTotalPrice(precent));

        break;
      }
      case "sum": {
        const sum = rate?.total_price
			? parseInt(totalExtra.replace(',', ''), 10) +
				(+rate?.total_price -
					hotel?.promocodes[0]?.discount)
			: 0
        dispatch(setTotalPrice(sum));
        break;
      }
    }
  }, [dispatch, hotel?.promocodes]);

  const reApplyingDiscount = useCallback(() => {
    switch (hotel?.promocodes[0]?.discount_type) {
      case "percent": {
        console.log("totalPriceSale: ", totalPriceSale);

		const resultPrecent = totalPriceSale - totalPrice
		const precent = Math.ceil(
			totalPrice + resultPrecent
		)
		dispatch(setTotalPrice(precent))
        break;
      }
      case "sum": {
        const sum = totalPrice + hotel?.promocodes[0]?.discount;
        dispatch(setTotalPrice(sum));
        break;
      }
    }
  }, [dispatch, hotel?.promocodes, totalPrice]);

  useEffect(() => {
    if (promocode) {
      dispatch(setIsDisabled(true));
      applyingDiscount();
    } else {
      dispatch(setIsDisabled(false));
    //   applyingDiscount();
    }
  }, [applyingDiscount, promocode]);

  if (data) {
    return (
      <div className={styles.thanks}>
        <p
          className={styles.title}
          style={{
            color:
              hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
          }}
        >
          Thanks for booking!
        </p>
        <span>Your order ID: {data.id}</span>
      </div>
    );
  }

  console.log('totalPrice======: ', totalPrice)

  return (
		<main className={styles.content}>
			{request || failed ? (
				<Loader request={request} />
			) : (
				<div className={styles.container}>
					<form className={styles.form}>
						<div className={styles.orders}>
							<h2
								className={styles.title}
								style={{
									color:
										hotel?.primary_color !== '#ffffff'
											? hotel?.primary_color
											: '',
								}}
							>
								Your Reservation
							</h2>
							<div className={styles.results}>
								<Order rate={rate} room={room} />
							</div>
							{rate?.total_price && (
								<div className={styles.subtotal}>
									<span
										className={`${styles.text} ${styles.subtotalText}`}
										style={{
											color:
												hotel?.primary_color !== '#ffffff'
													? hotel?.primary_color
													: '',
										}}
									>
										Subtotal
									</span>
									{/*<span className={`${styles.text} ${styles.subtotalValue} ${styles.subtotalOld}`}><s>$ 1,712</s></span>*/}
									{promocode && (
										<span
											className={styles.subtotalValueNoPromocode}
										>{`${currency} ${numberWithCommas(
											+rate?.total_price
										)}`}</span>
									)}

									<span
										className={`${styles.text} ${styles.subtotalValue}`}
										style={{
											color:
												hotel?.primary_color !== '#ffffff'
													? hotel?.primary_color
													: '',
										}}
									>
										{promocode
											? `${currency} ${displayDiscount(+rate?.total_price)}`
											: `${currency} ${numberWithCommas(+rate?.total_price)}`}
									</span>
								</div>
							)}
							{extra && (
								<div className={styles.total}>
									<span
										className={`${styles.text}`}
										style={{
											color:
												hotel?.primary_color !== '#ffffff'
													? hotel?.primary_color
													: '',
										}}
									>
										Extra
									</span>
									<span
										className={`${styles.text}`}
										style={{
											color:
												hotel?.primary_color !== '#ffffff'
													? hotel?.primary_color
													: '',
										}}
									>{`${currency} ${totalExtraNumber}`}</span>
								</div>
							)}
							<div className={styles.total}>
								<span
									className={`${styles.text} font-bold`}
									style={{
										color:
											hotel?.primary_color !== '#ffffff'
												? hotel?.primary_color
												: '',
									}}
								>
									Total
								</span>
								{/* ===== */}

								<span
									className={`${styles.text} font-bold`}
									style={{
										color:
											hotel?.primary_color !== '#ffffff'
												? hotel?.primary_color
												: '',
									}}
								>{`${currency} ${
									totalPrice ? numberWithCommas(totalPrice) : 0
									// totalPrice
								}`}</span>
							</div>
							{/*<Link className={styles.link} to="/booking/accommodations">+ Add more room</Link>*/}
						</div>
						{room && ( //!
							<>
								<div className={styles.inputs}>
									<h2
										className={styles.title}
										style={{
											color:
												hotel?.primary_color !== '#ffffff'
													? hotel?.primary_color
													: '',
										}}
									>
										Guest Information
									</h2>
									{hotel?.promocodes.length !== 0 && (
										<div className={styles.input}>
											<div className={styles.promocodeWrapper}>
												<input
													// className={`${
													//   isDisabled
													//     ? styles.inputPromoDisabled
													//     : styles.inputPromo
													// } ${styles.displayFlex} ${
													//   errors.promocode ? styles.error : ""
													// }`}
													className={`${styles.inputPromo} ${
														styles.displayFlex
													} ${errors.promocode ? styles.error : ''}`}
													type='text'
													name='promocode'
													id='promocode'
													placeholder={promocode ? promocode : 'Promo code'}
													value={promocode ? promocode : values.promocode}
													onChange={handleInputChange}
													disabled={isDisabled}
													style={{
														color:
															hotel?.primary_color !== '#ffffff'
																? hotel?.primary_color
																: '',
														background:
															hotel?.fourth_color !== '#ffffff'
																? hotel?.fourth_color
																: '',
													}}
												/>
												<Button
													title={!promocode ? 'Apply' : 'Remove'}
													buttonClass={styles.applyPromocode}
													onChange={
														!promocode ? applyPromoCode : removePromoCode
													}
													containerStyles={{
														color:
															hotel?.third_color !== '#ffffff'
																? hotel?.third_color
																: '',
														background:
															hotel?.secondary_color !== '#ffffff'
																? hotel?.secondary_color
																: '',
													}}
												/>
											</div>
											{errors.promocode && (
												<p className={styles.inputError}>
													This promo code is not valid
												</p>
											)}
											{promocode && (
												<p
													className={styles.inputSuccess}
													style={{
														color:
															hotel?.primary_color !== '#ffffff'
																? hotel?.primary_color
																: '',
													}}
												>
													You have unlocked 1 special offer!
												</p>
											)}
										</div>
									)}
									<div className={styles.input}>
										<input
											className={errors.firstname ? styles.error : ''}
											type='text'
											name='firstname'
											id='firstname'
											placeholder='First Name'
											value={values.firstname}
											onChange={handleInputChange}
											style={{
												color:
													hotel?.primary_color !== '#ffffff'
														? hotel?.primary_color
														: '',
												background:
													hotel?.fourth_color !== '#ffffff'
														? hotel?.fourth_color
														: '',
											}}
										/>
										<span className={styles.required}>*</span>
										{errors.firstname && (
											<p className={styles.inputError}>Field cannot be blank</p>
										)}
									</div>
									<div className={styles.input}>
										<input
											className={errors.lastname ? styles.error : ''}
											type='text'
											name='lastname'
											id='lastname'
											placeholder='Last Name'
											value={values.lastname}
											onChange={handleInputChange}
											style={{
												color:
													hotel?.primary_color !== '#ffffff'
														? hotel?.primary_color
														: '',
												background:
													hotel?.fourth_color !== '#ffffff'
														? hotel?.fourth_color
														: '',
											}}
										/>
										<span className={styles.required}>*</span>
										{errors.lastname && (
											<p className={styles.inputError}>Field cannot be blank</p>
										)}
									</div>
									<div className={styles.input}>
										<input
											type='tel'
											name='phone'
											id='phone'
											value={values.phone}
											placeholder='Phone Number'
											onInput={handleInputTel}
											onChange={handleInputChange}
											style={{
												color:
													hotel?.primary_color !== '#ffffff'
														? hotel?.primary_color
														: '',
												background:
													hotel?.fourth_color !== '#ffffff'
														? hotel?.fourth_color
														: '',
											}}
										/>
									</div>
									<div className={styles.input}>
										<input
											className={errors.email ? styles.error : ''}
											type='email'
											name='email'
											id='email'
											placeholder='Email'
											value={values.email}
											onChange={handleInputChange}
											style={{
												color:
													hotel?.primary_color !== '#ffffff'
														? hotel?.primary_color
														: '',
												background:
													hotel?.fourth_color !== '#ffffff'
														? hotel?.fourth_color
														: '',
											}}
										/>
										<span className={styles.required}>*</span>
										{errors.email && (
											<p className={styles.inputError}>Field cannot be blank</p>
										)}
									</div>
									<div className={styles.input}>
										<Select
											unstyled
											components={{ DropdownIndicator }}
											styles={colourStyles}
											className={`${styles.select} ${
												errors.country ? styles.error : ''
											}`}
											placeholder='Country'
											defaultValue={values.country}
											isSearchable
											isMulti={false}
											onChange={handleCountryChange}
											options={countryArr}
										/>
										<span className={styles.required}>*</span>
										{errors.country && (
											<p className={styles.inputError}>Field cannot be blank</p>
										)}
									</div>
									<div className={styles.input}>
										<textarea
											ref={textRef}
											name='message'
											id='message'
											rows={3}
											onChange={handleInputChange}
											defaultValue={values.message}
											placeholder='Additional Comments:'
											style={{
												color:
													hotel?.primary_color !== '#ffffff'
														? hotel?.primary_color
														: '',
												background:
													hotel?.fourth_color !== '#ffffff'
														? hotel?.fourth_color
														: '',
											}}
										></textarea>
									</div>
								</div>
								<div className={styles.conditions}>
									<div className={styles.condition}>
										{/*<div className={styles.checkbox}>*/}
										{/*  <input*/}
										{/*    type="checkbox"*/}
										{/*    name="address"*/}
										{/*    id="address"*/}
										{/*    checked={addressChecked}*/}
										{/*    onChange={() => setAddressChecked(!addressChecked)}*/}
										{/*  />*/}
										{/*  <label htmlFor="address" className={`${styles.checkboxLabel} ${addressChecked ? styles.checked : ''}`}>*/}
										{/*    Address*/}
										{/*  </label>*/}
										{/*  <span className={styles.checkboxText}>Use the same address as contact information.</span>*/}
										{/*</div>*/}
										<div className={styles.checkbox}>
											<input
												type='checkbox'
												id='terms'
												name='terms'
												checked={values.terms}
												onChange={handleInputChange}
											/>
											<label
												htmlFor='terms'
												className={`${styles.checkboxLabel} ${
													values.terms ? styles.checked : ''
												}`}
												style={{
													color:
														hotel?.primary_color !== '#ffffff'
															? hotel?.primary_color
															: '',
													background:
														values.terms && hotel?.primary_color !== '#ffffff'
															? hotel?.primary_color
															: values.terms &&
															  hotel?.fourth_color !== '#ffffff'
															? hotel?.fourth_color
															: '',
												}}
											>
												Address
											</label>
											<span
												className={styles.checkboxText}
												style={{
													color:
														hotel?.primary_color !== '#ffffff'
															? hotel?.primary_color
															: '',
												}}
											>
												I have read and agree to the{' '}
												{/* <Link
														to="#"
														onClick={handleOpenTerms}
														style={{
															color:
															hotel?.secondary_color !== "#ffffff"
																? hotel?.secondary_color
																: "",
														}}
														>
														Terms & Conditions
														</Link>{" "} */}
												{/* and{" "} */}
												<Link
													to='#'
													onClick={handleOpenPolicy}
													style={{
														color:
															hotel?.secondary_color !== '#ffffff'
																? hotel?.secondary_color
																: '',
													}}
												>
													Privacy Policy
												</Link>
											</span>
										</div>
										{!checked && !values.terms && (
											<p
												className={`${styles.info} ${styles.checkboxText} ${styles.textError}`}
												style={{
													color:
														hotel?.primary_color !== '#ffffff'
															? hotel?.primary_color
															: '',
												}}
											>
												Please review the Terms & Conditions for this
												reservation and check the box to continue.
											</p>
										)}
										{/*<p className={`${styles.info} ${styles.checkboxText}`}>Prices in USD shown are for informational purposes only. Deposits and total charges will be charged in THB.</p>*/}
										<Button
											title='BOOK NOW'
											buttonClass={styles.submit}
											onChange={handleFormSubmit}
											containerStyles={{
												color:
													hotel?.third_color !== '#ffffff'
														? hotel?.third_color
														: '',
												background:
													hotel?.secondary_color !== '#ffffff'
														? hotel?.secondary_color
														: '',
											}}
										/>
									</div>
								</div>
							</>
						)}
					</form>
				</div>
			)}
			<AnimatePresence>
				{openTerms && policy && (
					<Modal onClose={handleCloseTerms} modalStyle={styles.termModal}>
						<ReservationTerms terms={policy} />
					</Modal>
				)}
			</AnimatePresence>
			<AnimatePresence>
				{openPolicy && (
					<Modal onClose={handleClosePolicy}>
						<ReservationPolicy />
					</Modal>
				)}
			</AnimatePresence>
		</main>
	)
};

export default Reservation;
