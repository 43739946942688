import {GET_PROPERTIES_REQUEST, GET_PROPERTIES_SUCCESS, GET_PROPERTIES_FAILED} from "../actions/properties";
import {IPropertiesState, TPropertiesActions} from "../types/properties";

export const initialState: IPropertiesState = {
  ids: [],
  request: false,
  failed: false,
}

export const propertiesReducer = (state: IPropertiesState = initialState, action: TPropertiesActions) => {
  switch (action.type) {
    case GET_PROPERTIES_REQUEST: {
      return {
        ...state,
        request: true,
        failed: false
      };
    }
    case GET_PROPERTIES_SUCCESS: {
      return {
        ...state,
        request: false,
        ids: action.ids,
        failed: false
      };
    }
    case GET_PROPERTIES_FAILED: {
      return {
        ...state,
        request: false,
        failed: true
      };
    }
    default: {
      return state;
    }
  }
}