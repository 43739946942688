import React, { useEffect } from 'react'
import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";
import "./calendar.scss";
// import "./calendarTwo.scss";
import s from "./calendar.module.scss";

import calendarPrev from "../../assets/images/calendarPrev.svg";
import calendarNext from "../../assets/images/calendarNext.svg";
import { useAppSelector } from "../../hooks/redux";
import clsx from "clsx";
import { format, isBefore, startOfToday, addDays } from 'date-fns'

const PrevIco = () => {
  return <img src={calendarPrev} alt="prev" />;
};
const NextIco = () => {
  return <img src={calendarNext} alt="next" />;
};

interface CalendarProps {
  isVisible: boolean;
  value: [Date, Date];
  setValue: (dates: [Date, Date]) => void;
  double?: boolean;
}

const CalendarWrapper = ({
  setValue,
  value,
  isVisible,
  double = false,
}: CalendarProps) => {
  const { closed } = useAppSelector((store) => store.property);
  const { hotel } = useAppSelector((store) => store.hotelBySlug);

  const styleCalendar = {
    ".react-calendar--selectRange .react-calendar__tile--hover": {
      background: "red",
    },
    ".react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus":
      {
        background: "rgba(85, 197, 182, 0.8)",
      },
  };

  	// useEffect(() => {
	// 	if (closed.length > 0) {
	// 		const today = startOfToday()
	// 		let nextAvailableDate = today

	// 		// Находим первую доступную дату после закрытых дат
	// 		while (
	// 			closed.includes(format(nextAvailableDate, 'yyyy-MM-dd')) ||
	// 			isBefore(nextAvailableDate, today)
	// 		) {
	// 			nextAvailableDate = addDays(nextAvailableDate, 1)
	// 		}

	// 		setValue([nextAvailableDate, addDays(nextAvailableDate, 1)])
	// 	}
	// }, [closed])
	useEffect(() => {
		if (closed.length > 0) {
			const today = startOfToday()
			let nextAvailableDate = today

			// Находим первую доступную дату
			while (closed.includes(format(nextAvailableDate, 'yyyy-MM-dd'))) {
				nextAvailableDate = addDays(nextAvailableDate, 1)
			}

			setValue([nextAvailableDate, addDays(nextAvailableDate, 1)])

			// Теперь ищем вторую доступную дату после первой выбранной
			let secondAvailableDate = addDays(nextAvailableDate, 1)
			while (closed.includes(format(secondAvailableDate, 'yyyy-MM-dd'))) {
				secondAvailableDate = addDays(secondAvailableDate, 1)
			}

			setValue([nextAvailableDate, secondAvailableDate])
		}
	}, [closed])

  return (
		<Calendar
			prevLabel={PrevIco()}
			nextLabel={NextIco()}
			className={[s.calendarContainer, isVisible ? s.displayBlock : '']}
			prev2Label={null}
			next2Label={null}
			view='month'
			showDoubleView={double}
			selectRange
			// @ts-ignore
			onChange={setValue}
			value={value}
			locale='en-US'
			tileDisabled={({
				activeStartDate,
				date,
				view,
			}: {
				activeStartDate: Date
				date: Date
				view: string
			}) => {
				// let day: string = date.toISOString().split("T")[0];
				let day: string = format(date, 'yyyy-MM-dd')
				const today = startOfToday()
				return closed.includes(day) || isBefore(date, today)
			}}
			// style={{
			//   // color: hotel?.third_color,
			//   backgroundColor: hotel?.primary_color,
			// }}
			style={styleCalendar}
		/>
	)
};

export default CalendarWrapper;
