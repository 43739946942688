import React from "react";
import {Link} from "react-router-dom";

import styles from './reservation.module.scss';

const ReservationPolicy = () => {

  return (
    <div className={styles.policy}>
      <p>This hotel booking engine is provided by Ace Marketing Solutions. We understand the importance of privacy and are committed to protecting the personal information of our users. This Privacy Policy outlines our practices for collecting, using, maintaining, protecting, and disclosing information when you use our hotel booking engine. Please read this Privacy Policy carefully before using our services.</p>
      <h2>Information We Collect</h2>
      <p>We may collect personal information such as your name, address, phone number, email address, and payment information when you book a hotel reservation through our website. We also collect non-personal information such as your IP address, browser type, operating system, and the pages you visit on our website.</p>
      <h2>How We Use Your Information</h2>
      <p>We may use your personal information to process your hotel reservation, communicate with you about your reservation, and provide customer support. We may also use your information to improve our services, personalize your experience, and send promotional emails about our products and services.</p>
      <p>We may share your personal information with third-party service providers such as hotels, payment processors, and customer support providers to facilitate your hotel reservation. We may also share your information with government authorities or law enforcement agencies as required by law.</p>
      <h2>How We Protect Your Information</h2>
      <p>We may update our Privacy Policy from time to time, and we will notify you of any changes by posting the new Privacy Policy on our website. Your continued use of our services after any changes to the Privacy Policy constitutes your acceptance of the updated policy.</p>
      <h2>Contact Us</h2>
      <p>If you have any questions or concerns about our Privacy Policy, please contact us at <Link to="mailto:hotelsupport@acemsthailand.com">hotelsupport@acemsthailand.com</Link>.</p>
    </div>
  );
}

export default ReservationPolicy;