import React, { useState } from "react";
import { Info, IRatePlans } from "../../utils/types";

import styles from "./rate-plan.module.scss";
import { useAppSelector } from "../../hooks/redux";

const RatePlan = ({
  item,
  checked,
  onChange,
}: {
  item: IRatePlans;
  checked: Info;
  onChange: (el: Info, id: string | null) => void;
}) => {
  const { hotel } = useAppSelector((store) => store.hotelBySlug);
  const { promocode } = useAppSelector((store) => store.booking);

  const [isHovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const hoverStyleTeme = {
    backgroundColor:
      hotel?.secondary_color !== "#ffffff" && isHovered
        ? hotel?.secondary_color
        : "",
  };

  const displayDiscount = (total_price: number) => {
    console.log("total_price", total_price);
    if (total_price === 0) {
      return "0";
    }
    if (!total_price) return "";

    switch (hotel?.promocodes[0]?.discount_type) {
      case "percent": {
        const precent = Math.ceil(
          total_price - (total_price / 100) * hotel?.promocodes[0]?.discount
        );
        return precent.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      case "sum": {
        const sum = total_price - hotel?.promocodes[0]?.discount;
        return sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  };
  return (
		<div
			className={styles.variant}
			key={item.id.toString()}
			onClick={() => onChange(item.total_price, item.id)}
			style={hoverStyleTeme}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<label
				className={`${styles.radio} ${
					checked === item.total_price ? styles.checked : ''
				}`}
				style={{
					background:
						hotel?.secondary_color !== '#ffffff' && checked === item.total_price
							? hotel?.secondary_color
							: '',
				}}
			>
				<input hidden type='radio' name='deals' value={item.total_price} />
			</label>
			<p
				className={styles.text}
				style={{
					color: hotel?.primary_color !== '#ffffff' ? hotel?.primary_color : '',
				}}
			>
				<span>{item.title}</span>
				{item.cancellation_policy?.title && (
					<span>{item.cancellation_policy?.title}</span>
				)}
			</p>
			{item.total_price && (
				<div className={styles.price}>
					{/*{item.oldPrice && (*/}
					{/*  <span className={styles.old}><s>${item.oldPrice}</s></span>*/}
					{/*)}*/}
					{promocode && (
						<span
							className={styles.old}
							style={{
								color:
									hotel?.third_color !== '#ffffff' && isHovered
										? hotel?.third_color
										: '',
							}}
						>
							{parseInt(item.total_price)} {item.currency}
						</span>
					)}

					<span
						className={styles.current}
						style={{
							color:
								hotel?.third_color !== '#ffffff' && isHovered
									? hotel?.third_color
									: '',
						}}
					>
						{promocode
							? displayDiscount(parseInt(item.total_price))
							: parseInt(item.total_price)}{' '}
						{item.currency}
						{/* {parseInt(item.total_price)} {item.currency} */}
					</span>
				</div>
			)}
		</div>
	)
};

export default RatePlan;
