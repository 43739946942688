import React, { useCallback } from "react";

import styles from "./extra.module.scss";
import Button from "../../ui/Button/Button";
import { useAppSelector } from "../../hooks/redux";
import { Extras } from "../../services/types/hotelBySlug";

interface IExtraProps {
  props: Extras;
  onClick: (item: Extras, checked?: boolean) => void;
}

const Extra = ({ props, onClick }: IExtraProps) => {
  const { currency } = useAppSelector((store) => store.property);
  const { extra } = useAppSelector((store) => store.booking);
  const { hotel } = useAppSelector((store) => store.hotelBySlug);

  const active = useCallback(() => {
    const ex = extra.find(
      (item: {
        title: string;
        price: number;
        checked: boolean;
        id: number;
        price_type: string;
      }) => item.id === props.id
    );
    return ex?.checked;
  }, [extra, props.id]);

  const handleAddExtra = () => {
    onClick(props, active());
  };

  return (
    <li className={styles.item}>
      <span
        className={styles.itemTitle}
        style={{
          color:
            hotel?.secondary_color !== "#ffffff" ? hotel?.secondary_color : "",
        }}
      >
        {props.title}
      </span>
      <span
        className={styles.itemText}
        style={{
          color: hotel?.primary_color !== "#ffffff" ? hotel?.primary_color : "",
        }}
      >
        {props.price_type_display}
      </span>
      <span
        className={styles.itemPrice}
        style={{
          color:
            hotel?.secondary_color !== "#ffffff" ? hotel?.secondary_color : "",
        }}
      >{`${currency} ${props.price}`}</span>
      <Button
        buttonClass={styles.add}
        title={`${active() ? "REMOVE" : "ADD +"}`}
        onChange={handleAddExtra}
        containerStyles={{
          background:
            hotel?.secondary_color !== "#ffffff" ? hotel?.secondary_color : "",
          color: hotel?.third_color !== "#ffffff" ? hotel?.third_color : "",
        }}
      />
    </li>
  );
};

export default Extra;
