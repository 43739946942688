import React, { FormEvent } from "react";

import styles from "./button.module.scss";

type TButton = {
  title: string;
  onChange?: (e: FormEvent) => void;
  buttonClass?: string;
  disabled?: boolean;
  containerStyles?: React.CSSProperties;
};

const Button = ({
  title,
  onChange,
  buttonClass,
  disabled,
  containerStyles,
}: TButton) => {
  return (
    <button
      className={`${
        disabled ? styles.buttonDisabled : styles.button
      } ${buttonClass}`}
      onClick={onChange}
      disabled={disabled}
      style={{ ...containerStyles }}
    >
      {title}
    </button>
  );
};

export default Button;
