import React, { ChangeEvent, useEffect, useState } from "react";
import Calendar from "../Calendar/Calendar";
import MainButton from "../MainButton/MainButton";
import { NavLink } from "react-router-dom";

import s from "./book-now.module.scss";
import Select from "../../ui/Select/Select";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  setAdults,
  setChildren,
  setDates,
  setIsDisabled,
  setPromocode,
} from "../../services/actions/booking";
import {
  IPromocodeErrors,
  IPromocodeForm,
  Info,
  TDates,
} from "../../utils/types";
import { useWindowWidth } from "../../utils/helpers";
import Button from "../../ui/Button/Button";

const BookNow = () => {
  const { dates, adults, children, promocode, isDisabled } = useAppSelector(
    (store) => store.booking
  );
  const { hotel } = useAppSelector((store) => store.hotelBySlug);
  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const width: number | undefined = useWindowWidth();
  const [values, setValues] = useState<string>("");
  const [errors, setErrors] = useState<IPromocodeErrors>({
    promocode: false,
  });

  const changeAdults = (el: Info) => dispatch(setAdults(el));
  const changeChildren = (el: Info) => dispatch(setChildren(el));
  const changeDates = (dates: TDates) => dispatch(setDates(dates));

  const start = (dateString: Date) => {
    const date: Date = new Date(dateString);
    return date.getDate();
  };
  const end = (dateString: Date) => {
    const date: Date = new Date(dateString);
    return date.toString().split(" ")[1];
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const value = target.value;
    const name = target.name;
    setValues(value);
    setErrors({ ...errors, [name]: !value });
  };

  const applyPromoCode = () => {
    if (!values) {
      setErrors({
        ...errors,
        promocode: !values,
      });
    }

    if (values === hotel?.promocodes[0]?.code) {
      dispatch(setPromocode(values));
      dispatch(setIsDisabled(true));
    } else {
      setErrors({
        ...errors,
        promocode: values !== hotel?.promocodes[0]?.code,
      });
    }
  };

  const removePromoCode = () => {
    dispatch(setPromocode(""));
    setValues("");
  };

  useEffect(() => {
    if (promocode) {
      dispatch(setIsDisabled(true));
    } else {
      dispatch(setIsDisabled(false));
    }
  }, [dispatch, promocode]);

  console.log("hotel?.third_color: ", hotel?.third_color);

  return (
		<div className={`${s.container} book-now`}>
			{width && width >= 768 && (
				//
				<Calendar
					isVisible={isVisible}
					value={[dates[0], dates[1]]}
					setValue={changeDates}
				/>
			)}
			<div
				className={s.makeReservation}
				style={{
					backgroundColor:
						hotel?.primary_color !== '#ffffff' ? hotel?.primary_color : '',
				}}
			>
				<h1
					className={s.header}
					style={{
						color: hotel?.third_color !== '#ffffff' ? hotel?.third_color : '',
						background:
							hotel?.secondary_color !== '#ffffff'
								? hotel?.secondary_color
								: '',
					}}
				>
					Make a reservation
				</h1>
				<div className={s.content}>
					<div className={s.datesRow}>
						<button
							onClick={() => setIsVisible(true)}
							className={s.dateColumn}
							style={{
								background:
									hotel?.secondary_color !== '#ffffff'
										? hotel?.secondary_color
										: '',
							}}
						>
							<div
								className={`${s.text} ${s.title}`}
								style={{
									color:
										hotel?.third_color !== '#ffffff' ? hotel?.third_color : '',
								}}
							>
								CHECK IN
							</div>
							<div
								className={s.date}
								style={{
									color:
										hotel?.third_color !== '#ffffff' ? hotel?.third_color : '',
								}}
							>
								{start(dates[0])}
							</div>
							<div
								className={s.text}
								style={{
									color:
										hotel?.third_color !== '#ffffff' ? hotel?.third_color : '',
								}}
							>
								{end(dates[0])}
							</div>
						</button>
						<button
							onClick={() => setIsVisible(true)}
							className={s.dateColumn}
							style={{
								background:
									hotel?.secondary_color !== '#ffffff'
										? hotel?.secondary_color
										: '',
							}}
						>
							<div
								className={`${s.text} ${s.title}`}
								style={{
									color:
										hotel?.third_color !== '#ffffff' ? hotel?.third_color : '',
								}}
							>
								CHECK OUT
							</div>
							<div
								className={s.date}
								style={{
									color:
										hotel?.third_color !== '#ffffff' ? hotel?.third_color : '',
								}}
							>
								{start(dates[1])}
							</div>
							<div
								className={s.text}
								style={{
									color:
										hotel?.third_color !== '#ffffff' ? hotel?.third_color : '',
								}}
							>
								{end(dates[1])}
							</div>
						</button>
					</div>
					{width && width < 768 && (
						//
						<Calendar
							isVisible={isVisible}
							value={[dates[0], dates[1]]}
							setValue={changeDates}
						/>
					)}
					<div className={`${s.optionsRow} ${isVisible && s.displayFlex}`}>
						<div className={s.optionsColumn}>
							<div
								className={s.optionTitle}
								style={{
									color:
										hotel?.third_color !== '#ffffff' ? hotel?.third_color : '',
								}}
							>
								Adults
							</div>
							<Select
								selectClass={s.select}
								textClass={s.selected}
								listClass={s.list}
								optionClass={s.option}
								optionsList={[
									{ value: 0, label: 0 },
									{ value: 1, label: 1 },
									{ value: 2, label: 2 },
									{ value: 3, label: 3 },
									{ value: 4, label: 4 },
								]}
								onChange={changeAdults}
								defaultText={adults}
							/>
						</div>
						<div className={s.optionsColumn}>
							<div
								className={s.optionTitle}
								style={{
									color:
										hotel?.third_color !== '#ffffff' ? hotel?.third_color : '',
								}}
							>
								Children
							</div>
							<Select
								selectClass={s.select}
								textClass={s.selected}
								listClass={s.list}
								optionClass={s.option}
								optionsList={[
									{ value: 0, label: 0 },
									{ value: 1, label: 1 },
									{ value: 2, label: 2 },
									{ value: 3, label: 3 },
									{ value: 4, label: 4 },
								]}
								onChange={changeChildren}
								defaultText={children}
							/>
						</div>
					</div>
					{hotel?.promocodes.length !== 0 && (
						<div className={s.inputWrap}>
							<div className={s.promocodeWrapper}>
								<input
									className={`${s.inputPromo} ${isVisible && s.displayFlex} ${
										errors.promocode ? s.error : ''
									}`}
									type='text'
									name='promocode'
									id='promocode'
									placeholder={'Promo code'}
									value={promocode ? promocode : values}
									onChange={handleInputChange}
									disabled={isDisabled}
									style={{
										color:
											hotel?.secondary_color !== '#ffffff'
												? hotel?.secondary_color
												: '',
									}}
								/>
								<Button
									title={!promocode ? 'Apply' : 'Remove'}
									buttonClass={s.applyPromocode}
									onChange={!promocode ? applyPromoCode : removePromoCode}
									containerStyles={{
										color:
											hotel?.third_color !== '#ffffff'
												? hotel?.third_color
												: '',
										background:
											hotel?.secondary_color !== '#ffffff'
												? hotel?.secondary_color
												: '',
									}}
								/>
							</div>
							{errors.promocode && (
								<p className={s.inputError}>This promo code is not valid</p>
							)}
							{promocode && (
								<p
									className={s.inputSuccess}
									style={{
										color:
											hotel?.third_color !== '#ffffff'
												? hotel?.third_color
												: '',
									}}
								>
									You have unlocked 1 special offer!
								</p>
							)}
						</div>
					)}

					{/* TODO
						Обрабочик нажатия кнопки */}
					<NavLink className={s.fill} to={'booking/accommodations'}>
						<MainButton
							containerStyles={{
								color:
									hotel?.third_color !== '#ffffff' ? hotel?.third_color : '',
								background:
									hotel?.secondary_color !== '#ffffff'
										? hotel?.secondary_color
										: '',
							}}
							title='BOOK NOW'
						/>
					</NavLink>
				</div>
			</div>
		</div>
	)
};

export default BookNow;
