import React, { FormEvent } from "react";
import Select from "../../ui/Select/Select";
import { Info, ISelectOption } from "../../utils/types";
import styles from "./guests.module.scss";
import Button from "../../ui/Button/Button";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { setAdults, setChildren } from "../../services/actions/booking";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { color } from "framer-motion";

const Adults: ISelectOption[] = [
  { value: 1, label: "1 Adult" },
  { value: 2, label: "2 Adults" },
  { value: 3, label: "3 Adults" },
  { value: 4, label: "4 Adults" },
  { value: 5, label: "5 Adults" },
];

const Children: ISelectOption[] = [
  { value: 0, label: "0 Children (under 12 yrs)" },
  { value: 1, label: "1 Children (under 12 yrs)" },
  { value: 2, label: "2 Children (under 12 yrs)" },
  { value: 3, label: "3 Children (under 12 yrs)" },
  { value: 4, label: "4 Children (under 12 yrs)" },
  { value: 5, label: "5 Children (under 12 yrs)" },
];

const Guests = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useAppDispatch();
  let { id } = useParams();
  const { adults, children } = useAppSelector((store) => store.booking);
  const { hotel } = useAppSelector((store) => store.hotelBySlug);

  const changeAdults = (el: Info) => dispatch(setAdults(el));
  const changeChildren = (el: Info) => dispatch(setChildren(el));

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    console.log("Form submit");
  };

  return (
    <main className={styles.content}>
      <h1
        className={styles.title}
        style={{
          color:
            hotel?.secondary_color !== "#ffffff" ? hotel?.secondary_color : "",
        }}
      >
        Guests
      </h1>
      <form onSubmit={handleSubmit}>
        <div className={styles.selects}>
          <div className={styles.select}>
            <Select
              optionsList={Adults}
              onChange={changeAdults}
              defaultValue={adults}
              defaultText={`${adults} ${adults === 1 ? "Adult" : "Adults"}`}
            />
          </div>
          <div className={styles.select}>
            <Select
              optionsList={Children}
              onChange={changeChildren}
              defaultValue={children}
              defaultText={`${children} Children (under 12 yrs)`}
            />
          </div>
        </div>
        <div className={styles.row}>
          {/*<label htmlFor="code" hidden>Code</label>*/}
          {/*<input className={`input`} type="text" name="code" id="code" placeholder="Add code" />*/}
          <Button
            buttonClass={styles.button}
            onChange={() => navigate(`/${id}/booking/dates`)}
            title="UPDATE DATES GUEST"
            containerStyles={{
              background:
                hotel?.secondary_color !== "#ffffff"
                  ? hotel?.secondary_color
                  : "",
              color: hotel?.third_color !== "#ffffff" ? hotel?.third_color : "",
            }}
          />
        </div>
      </form>
    </main>
  );
};

export default Guests;
