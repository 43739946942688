import React, { FC, useEffect, useState } from "react";
import {createPortal} from 'react-dom';
import ModalOverlay from "./ModalOverlay";
import {IModal} from "../../utils/types";
import { motion } from "framer-motion";

import styles from "./modal.module.scss";

let modalRoot: HTMLElement | null = typeof document !== 'undefined' ? document.getElementById("modals") : null;

const Modal: FC<IModal> = ({children, onClose, modalStyle}) => {
  useEffect(() => {
    const closeOnEscape = (e: KeyboardEvent) => e.key === "Escape" ? onClose && onClose() : null;
    document.body.addEventListener("keydown", closeOnEscape);
    document.body.classList.add('no-scroll');

    return () => {
      document.body.removeEventListener("keydown", closeOnEscape);
      document.body.classList.remove('no-scroll');
    }
  }, [onClose]);

  useEffect(() => {

  }, []);


  return createPortal(
    <>
      <div className={styles.wrapper}>
        <motion.div
          className={`${modalStyle ? modalStyle : ''} ${styles.modal}`}
          initial={{opacity: 0, scale: 0.9}}
          animate={{opacity: 1, scale: 1}}
          exit={{opacity: 0, scale: 0.9}}
          transition={{duration: 0.3}}>
          <div className={styles.close} onClick={onClose}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <line x1="4" x2="28" y1="4" y2="28" stroke="#060820" fillOpacity="0.5" />
              <line x1="4" x2="28" y1="28" y2="4" stroke="#060820" fillOpacity="0.5" />
            </svg>
          </div>
          {children}
        </motion.div>
      </div>
      <ModalOverlay onClose={onClose}/>
    </>,
    modalRoot!
  );
};
export default Modal;
