import React from "react";
import Lottie from "lottie-react";
import loaderAnimation from "./loader.json";

import styles from './loader.module.scss';

const Loader = ({request = true}: {request?: boolean}) => {
  const animation = JSON.parse(JSON.stringify(loaderAnimation));

  return (
    <div className={styles.wrapper}>
      <div className={styles.loader}>
        {request ? (
          <Lottie animationData={animation} loop={true} />
        ) : (
          <div className={styles.error}>
            <p>Connection error!</p>
            <p>Please try again later.</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Loader;