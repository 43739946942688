import React, { useEffect } from "react";
import Room from "../../components/Room/Room";
import { IRoomData } from "../../utils/types";

import { getRoomTypes } from "../../services/actions/rooms";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";

import styles from "./accommodations.module.scss";
import { dateFormat } from "../../utils/helpers";
import Loader from "../../ui/Loader/Loader";

const Accommodations = () => {
  const { rooms, request, failed } = useAppSelector((store) => store.rooms);
  const { dates } = useAppSelector((store) => store.booking);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getRoomTypes(dateFormat(dates[0]), dateFormat(dates[1])));
  }, [dispatch, dates]);

  console.log("rooms: ", rooms);

  return (
    <main className={styles.container}>
      {request || failed ? (
        <Loader request={request} />
      ) : (
        <>
          {/* <form className={styles.form}>
            <label htmlFor="code" hidden>
              Code
            </label>
            <input type="text" name="code" id="code" placeholder="Add code" />
          </form> */}
          <ul className={styles.rooms}>
            {rooms.map((room: IRoomData) => (
              <li className={styles.room} key={room.id.toString()}>
                <Room {...room} />
              </li>
            ))}
          </ul>
        </>
      )}
    </main>
  );
};

export default Accommodations;
