import React from "react";

import s from "./main-button.module.scss";

interface MainButtonProps {
  title: string;
  containerStyles?: React.CSSProperties;
  onClick?: () => void;
}

const MainButton = ({ title, containerStyles, onClick }: MainButtonProps) => {
  return (
    <button
      onClick={onClick}
      style={{ ...containerStyles }}
      className={s.mainButton}
    >
      {title}
    </button>
  );
};

export default MainButton;
