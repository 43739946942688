import { AppDispatch } from "../../utils/types";
import { apiRequest } from "../../utils/api";
import { API2_URL } from "../../utils/constants";

export const GET_HOTELBYSLUG_REQUEST: "GET_HOTELBYSLUG_REQUEST" =
  "GET_HOTELBYSLUG_REQUEST";
export const GET_HOTELBYSLUG_SUCCESS: "GET_HOTELBYSLUG_SUCCESS" =
  "GET_HOTELBYSLUG_SUCCESS";
export const GET_HOTELBYSLUG_FAILED: "GET_HOTELBYSLUG_FAILED" =
  "GET_HOTELBYSLUG_FAILED";

export const getHotelBySlug = (slug: string) => (dispatch: AppDispatch) => {
  dispatch({ type: GET_HOTELBYSLUG_REQUEST });
  apiRequest(`${API2_URL}/hotels/${slug}`)
    .then((response) => {
      if (response) {
        dispatch({
          type: GET_HOTELBYSLUG_SUCCESS,
          hotel: response.hotel,
        });
      }
    })
    .catch((err) => {
      console.log("err: ", err);
      dispatch({ type: GET_HOTELBYSLUG_FAILED });
    });
};
