import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  redirect,
} from "react-router-dom";
import Booking from "./pages/Booking/Booking";
import MainPage from "./pages/MainPage/MainPage";
import NotFound from "./pages/NotFound/NotFound";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import { getClosedDays, getProperty } from "./services/actions/property";
import { getProperties } from "./services/actions/properties";
import { getHotelBySlug } from "./services/actions/hotelBySlug";
import { PROPERTY_ID } from "./utils/constants";
import Loader from "./ui/Loader/Loader";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Hotel } from "./services/types/hotelBySlug";

const path = window.location.pathname.split("/")[1];
export let EXTERNAL_ID = "";

const App = () => {
  const dispatch = useAppDispatch();
  const { ids } = useAppSelector((store) => store.properties);
  const { title, closed } = useAppSelector((store) => store.property);
  const { hotel, request, failed } = useAppSelector(
    (store) => store.hotelBySlug
  );
  const [apiHotel, setApiHotel] = useState<Hotel | null>(null);
  const [apiTitle, setApiTitle] = useState<string | null>(null);

  useEffect(() => {
    if (path) {
      dispatch(getHotelBySlug(path));
    }
  }, [dispatch]);

  useEffect(() => {
    if (hotel) {
      EXTERNAL_ID = hotel.external_id;
    }
  });

  useEffect(() => {
    dispatch(getProperties());
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getProperty(ids.includes(path) ? path : ids[0]));
  //   dispatch(getClosedDays(ids.includes(path) ? path : ids[0]));
  // }, [ids]);

  useEffect(() => {
    if (hotel) {
      dispatch(
        getProperty(
          hotel.slug === path || hotel.external_id === path
            ? hotel.external_id // PROPERTY_ID
            : hotel.external_id // PROPERTY_ID
        )
      );

      dispatch(
        getClosedDays(
          hotel.slug === path || hotel.external_id === path
            ? hotel.external_id // PROPERTY_ID
            : hotel.external_id // PROPERTY_ID
        )
      );
    }
  }, [hotel]);

  useEffect(() => {
    console.log("ids: ", ids);
    console.log("hotel: ", hotel);
  }, [ids, hotel]);

  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.removeItem("persist:root");
    };

    window.addEventListener("beforeunload", clearLocalStorage);

    return () => {
      window.removeEventListener("beforeunload", clearLocalStorage);
    };
  }, []);

  useEffect(() => {
    if (title) {
      setApiTitle(title);
      setApiHotel(hotel);
    }
  });

  return (
    <>
      <Helmet>
        {apiTitle && apiHotel ? (
          <>
            <title>{apiTitle} - Online Booking</title>
            <meta name="title" content={`${apiTitle} - Online Booking`} />
            <meta name="description" content={apiHotel.description} />
            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={`https://bookingengine.acemsthailand.com/${apiHotel.external_id}`}
            />
            <meta
              property="og:title"
              content={`${apiTitle} - Online Booking`}
            />
            <meta
              property="og:description"
              content={`Book direct with ${apiTitle} for the best rate.`}
            />
            <meta property="og:image" content={`${apiHotel.logo}`} />
          </>
        ) : (
          <>
            <title>ACE Hotel - Online Booking</title>
            <meta name="title" content="ACE Hotel - Online Booking" />
            <meta name="description" content="Book direct with ACE Hotel for the best rate."/>
            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content="https://bookingengine.acemsthailand.com/ace_hotel"
            />
            <meta property="og:title" content="ACE Hotel - Online Booking" />
            <meta
              property="og:description"
              content="Book direct with ACE Hotel for the best rate."
            />
            <meta property="og:image" content="%PUBLIC_URL%/preview.jpg" />
          </>
        )}
      </Helmet>
      <Router>
        <div className="App">
          <Routes>
            {hotel && (hotel.slug === path || hotel.external_id === path) ? (
              <Route path="/">
                <Route path=":id" element={<MainPage />} />
                <Route path=":id/booking/*" element={<Booking />} />
              </Route>
            ) : (
              <Route path="*" element={<NotFound />} />
            )}
          </Routes>
        </div>
      </Router>
    </>
  );
};

export default App;
