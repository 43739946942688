import {
  GET_HOTELBYSLUG_REQUEST,
  GET_HOTELBYSLUG_SUCCESS,
  GET_HOTELBYSLUG_FAILED,
} from "../actions/hotelBySlug";
import { IHotelBySlugState, THotelBySlugActions } from "../types/hotelBySlug";

export const initialState: IHotelBySlugState = {
  hotel: null,
  request: false,
  failed: false,
};

export const hotelBySlugReducer = (
  state: IHotelBySlugState = initialState,
  action: THotelBySlugActions
) => {
  switch (action.type) {
    case GET_HOTELBYSLUG_REQUEST: {
      return {
        ...state,
        request: true,
        failed: false,
      };
    }
    case GET_HOTELBYSLUG_SUCCESS: {
      return {
        ...state,
        request: false,
        hotel: action.hotel,
        // ids: action.ids,
        failed: false,
      };
    }
    case GET_HOTELBYSLUG_FAILED: {
      return {
        ...state,
        request: false,
        failed: true,
      };
    }
    default: {
      return state;
    }
  }
};
